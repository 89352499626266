import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  marginTop: '5rem'
}));

export const SectionOneContainerStyle = styled('div')(() => ({
  //   border: 'solid red',
  marginTop: '4rem'
}));
