import { styled } from '@mui/material/styles';

export const Label = styled('p')(() => ({
  fontSize: '15px',
  fontFamily: 'Poppins',
  marginBottom: '1rem'
}));

export const TextFieldContainer = styled('div')(() => ({
  width: '50%'
}));

export const FormContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  padding: '5rem 0rem'
}));

export const BannerContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  backgroundColor: '#86BB5E',
  height: '30vh',
  padding: '0rem 1rem'
}));
