import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar, Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React, { useEffect } from 'react';
import Auth from '../../../auth/Auth';
import AppFooter from '../../../components/AppFooter';
import NavBar from '../../../components/NavBar';
import jwt_decode from 'jwt-decode';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const EditProfile = () => {
  const auth = new Auth();
  const [userData, setUserData] = React.useState(null);
  const [Edit, setEdit] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const handleClickOpen = () => {
    if (!Edit) setOpen(true);
  };

  const deleteHandleClickOpen = () => {
    setIsDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHandleClose = () => {
    setIsDelete(false);
  };

  const getUserData = (profile) => {
    setUserData(profile);
  };

  const onDeleteHandler = () => {
    const id_token = localStorage.getItem('id_token');
    const decodedJWT = jwt_decode(id_token);

    axios
      .delete('http://localhost:3001/user', {
        data: {
          email: decodedJWT.email
        }
      })
      .then((res) => {
        if (decodedJWT?.sub) {
          axios
            .delete(
              `https://dev-tgl20n0exssx4c5r.us.auth0.com/api/v2/users/${decodedJWT.sub}`,
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_EXPLORER_TOKEN}`
                }
              }
            )
            .then((response) => {
              if (!response.data) {
                auth.logout();
              }
            })
            .catch((error) => console.log('error = ', error));
        }
      })
      .catch((err) => {
        alert('No Database Connection found!!!');
      });
  };

  useEffect(() => {
    auth.getProfile(getUserData);
  }, []);
  return (
    <>
      <NavBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5rem 4rem',
          gap: '4rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Avatar
            alt="img"
            src={userData?.picture}
            sx={{ width: 150, height: 150, outline: '8px solid #808000' }}
          />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {userData?.nickname}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '40%',
            minWidth: '20rem',
            maxWidth: '33rem',
            gap: '1rem',
            padding: '1rem',
            boxShadow: '0px 0px 14px 6px #cdcbcbd6',
            borderRadius: '1rem'
          }}
        >
          <Typography variant="h5" fontWeight={500}>
            Improve your reputation on Borrowed
          </Typography>
          <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <MailIcon
                sx={{
                  background: '#808000',
                  width: '3rem',
                  height: '3rem',
                  padding: '9px',
                  borderRadius: '50%',
                  color: 'white',
                  marginBottom: '6px'
                }}
              />
              <Typography>
                Email <br />
                Verified
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <CallIcon
                sx={{
                  width: '3rem',
                  height: '3rem',
                  padding: '9px',
                  borderRadius: '50%',
                  border: '1px solid',
                  marginBottom: '6px'
                }}
              />
              <Typography>
                Verify <br />
                Phone
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '40%',
            minWidth: '20rem',
            maxWidth: '33rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            boxShadow: '0px 0px 14px 6px #cdcbcbd6',
            borderRadius: '1rem',
            padding: '3rem'
          }}
        >
          <Typography variant="h4">Account</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="standard-basic"
              placeholder={userData?.nickname}
              variant="standard"
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: Edit ? false : true
              }}
              inputProps={{ onClick: () => handleClickOpen() }}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#808000',
                borderBottom: '1px solid #808080cf',
                fontWeight: 600
              }}
              onClick={() => setEdit(true)}
            >
              Edit
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="standard-basic"
              placeholder={userData?.email}
              variant="standard"
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: Edit ? false : true
              }}
              inputProps={{ onClick: () => handleClickOpen() }}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#808000',
                borderBottom: '1px solid #808080cf',
                fontWeight: 600
              }}
            >
              Edit
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="standard-basic"
              placeholder="Set Location"
              variant="standard"
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: Edit ? false : true
              }}
              inputProps={{ onClick: () => handleClickOpen() }}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#808000',
                borderBottom: '1px solid #808080cf',
                fontWeight: 600
              }}
            >
              Edit
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="standard-basic"
              placeholder="Add Phone Number"
              variant="standard"
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: Edit ? false : true
              }}
              inputProps={{ onClick: () => handleClickOpen() }}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#808000',
                borderBottom: '1px solid #808080cf',
                fontWeight: 600
              }}
            >
              Edit
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="standard-basic"
              placeholder="Password"
              variant="standard"
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: Edit ? false : true
              }}
              inputProps={{ onClick: () => handleClickOpen() }}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#808000',
                borderBottom: '1px solid #808080cf',
                fontWeight: 600
              }}
            >
              Edit
            </Typography>
          </Box>
        </Box>

        {/* Delete profile */}
        {/* <Box>
          <Button
            variant="outlined"
            color="error"
            sx={{ textTransform: 'capitalize' }}
            onClick={() => {
              deleteHandleClickOpen();
              if (open) onDeleteHandler();
            }}
          >
            Delete Profile
          </Button>
        </Box> */}
      </Box>

      {/* Dialog */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Click on{' '}
            <span style={{ color: '#808000', fontWeight: 'bold' }}>Edit</span>{' '}
            to perform action on this field
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for handle delete profile */}
      <Dialog
        open={isDelete}
        onClose={deleteHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this account ? This action is not
            reverseable
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteHandleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteHandleClose();
              onDeleteHandler();
            }}
            variant="outlined"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AppFooter />
    </>
  );
};

export default EditProfile;
