import { Button, Divider, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  AppleIcon,
  FacebookIcon,
  GmailIcon,
  loginCover
} from '../../../assets/images/AmazonS3/index';
import BorrowedTextLogo from '../../../assets/images/BorrowedTextLogo.png';
import logo from '../../../assets/images/borrowLogo.png';
import email from '../../../assets/images/email.png';
import password from '../../../assets/images/password.png';
import Auth from '../../../auth/Auth';
import { useAuth0 } from '@auth0/auth0-react';
import { userSelector } from '../../../redux/slices/UserSlice';

const minPass = 8;
let matches;

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),

  password: yup
    .string('Password Required')
    .required('Password Required')
    .min(
      minPass,
      ({}) =>
        `Minimum ${minPass} characters, 1 Capital letter & one symbol required`
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      `Minimum ${minPass} characters, 1 Capital letter & one symbol required`
    )
});

const styles = (theme) => ({
  buttonStyle: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.up('sm')]: {
      width: '80%'
    },

    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: theme.spacing(15)
  }
});

function SignInSide(props) {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const theme = useTheme();
  const navigate = useNavigate();
  matches = useMediaQuery(theme.breakpoints.down('md'));

  const auth = new Auth();
  const { error } = useSelector(userSelector);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      auth.login(values.email, values.password);
    }
  });

  return (
    <>
      <Box
        sx={{
          paddingTop: '1rem',
          paddingLeft: matches ? null : '2rem',
          textAlign: matches && 'center'
        }}
      >
        <Link to={'/'}>
          <Box component={'img'} src={logo} alt="logo" width={40} />
          <img
            src={BorrowedTextLogo}
            width={90}
            style={{ marginLeft: '6px' }}
            alt={'text'}
          />
        </Link>
      </Box>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            flexDirection={'column'}
            sx={{ display: { xs: 'none', sm: 'flex', marginTop: '2rem' } }}
          >
            {matches ? null : (
              <Box
                component={'img'}
                src={loginCover}
                alt="logo"
                sx={{ width: '50vw' }}
              />
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{ boxShadow: 'none' }}
        >
          <Box
            sx={{
              margin: matches ? '64px 2rem 64px 2rem' : '64px 5rem 64px 10rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '2rem'
              }}
            >
              <Typography
                sx={{
                  color: '#565601',
                  fontSize: '31.25px',
                  fontWeight: '400'
                }}
                variant={'h6'}
              >
                Login to Continue
              </Typography>
              <Typography sx={{ color: '#A0A0A0', fontSize: '20px' }}>
                Welcome back! Happy to have you back.
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              {error ? <Alert severity="error">{error}</Alert> : null}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ backgroundColor: '#E3E3E3' }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={email} alt="img" width={'26.67px'} />
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ backgroundColor: '#E3E3E3' }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={password} alt="img" width={'26.67px'} />
                    </InputAdornment>
                  )
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        color: '#808000',
                        '&.Mui-checked': { color: '#808000' }
                      }}
                    />
                  }
                  label="Remember me"
                />
                <span
                  style={{ fontWeight: '600', cursor: 'pointer' }}
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot Password
                </span>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  mb: '29px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    width: { xs: 150 },
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '20px',
                    boxShadow: '1px 2px 5px rgba(73, 73, 1, 0.54)',
                    '&:hover': { backgroundColor: '#808000' }
                  }}
                >
                  Sign In
                </Button>
              </Box>

              <Divider
                sx={{
                  '&::before, &::after': {
                    borderColor: '#565601',
                    borderWidth: 2
                  }
                }}
                spacing={1}
              >
                <Typography variant="h6">OR</Typography>
              </Divider>
            </form>

            <Grid
              container
              spacing={4}
              justifyContent="center"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <img
                  src={FacebookIcon}
                  height="60px"
                  style={{ cursor: 'pointer' }}
                  onClick={() => loginWithRedirect({ connection: 'facebook' })}
                />
              </Grid>
              <Grid item>
                <img
                  src={GmailIcon}
                  height="60px"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    loginWithRedirect({ connection: 'google-oauth2' })
                  }
                />
              </Grid>
              <Grid item>
                <img
                  src={AppleIcon}
                  height="60px"
                  style={{ cursor: 'pointer' }}
                  onClick={() => loginWithRedirect({ connection: 'apple' })}
                />
              </Grid>
            </Grid>
            {console.log('ccc', isAuthenticated, user)}
            <Box
              sx={{
                mt: 5,
                mb: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{ color: 'black', marginBottom: '1rem', fontSize: '20px' }}
              >
                {'Don’t have an account yet? Create one now'}
              </Typography>

              <NavLink to={'/signup'} style={{ textDecoration: 'none' }}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    color: 'black',
                    border: '1px solid #808000',
                    fontWeight: '400',
                    fontSize: '20px',
                    boxShadow: '1px 2px 5px rgba(73, 73, 1, 0.54)',
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: '#808000'
                    }
                  }}
                >
                  Sign Up Now
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(SignInSide);
