import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import s1 from '../../../assets/images/S1.png';
import s2 from '../../../assets/images/S2.png';
import { Link, useNavigate } from 'react-router-dom';
import InputValidatorv2 from '../../../components/InputValidatorv2';

function StayTuned() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate('/signup', { state: { inputemail: email } });
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          paddingTop: '10rem',
          paddingBottom: '5rem',
          boxSizing: 'border-box',
          background: '#F8FFF5'
        }}
      >
        <Typography
          sx={{
            fontSize: 'clamp(50px,5vw,64px)',
            fontWeight: 275,
            fontStyle: 'italic',
            fontFamily: 'Poppins',
            lineHeight: '5rem',
            paddingLeft: '2rem'
          }}
        >
          Stay{' '}
          <span style={{ paddingLeft: '1rem' }}>
            <img src={s1} width={90} alt="img" style={{ paddingTop: '2rem' }} />
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: 'clamp(50px,5vw,64px)',
            fontWeight: 500,
            fontFamily: 'Poppins',
            lineHeight: '5rem'
          }}
        >
          Tuned!
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingRight: '13rem'
          }}
        >
          <img src={s2} width={90} alt="img" />
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                padding: '5rem 0rem 2rem 0rem',
                fontSize: 24
              }}
            >
              Enter your email for updates!
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <InputValidatorv2 height={'4rem'} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default StayTuned;
