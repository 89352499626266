import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import ab1 from '../../../assets/images/ab1.png';
import ab2 from '../../../assets/images/ab2.png';
import ab3 from '../../../assets/images/ab3.png';
import ab4 from '../../../assets/images/ab4.png';
import ab5 from '../../../assets/images/ab5.png';
import aboutc1 from '../../../assets/images/aboutc1.png';
import aboutc2 from '../../../assets/images/aboutc2.png';
import aboutc3 from '../../../assets/images/aboutc3.png';
import aboutc4 from '../../../assets/images/aboutc4.png';
import aboutc5 from '../../../assets/images/aboutc5.png';
import s1 from '../../../assets/images/S1.png';
import s2 from '../../../assets/images/S2.png';
import NavBarV2 from '../../../components/NavBarV2';
import { MainContainer, SectionOneContainerStyle } from './Style';
import AppFooterv2 from '../../../components/AppFooterv2';
import { Container, Container2, Container3 } from '../Style';

function Herov3() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Container>
        <Container2>
          <Container3>
            <NavBarV2 />
            <MainContainer>
              {/* section 1 */}

              <Grid container>
                {/* p1 */}
                <Grid item xs={12} md={6}>
                  <SectionOneContainerStyle>
                    <Box
                      sx={{
                        textAlign: 'center',
                        marginTop: '3rem',
                        padding: '1rem',
                        boxSizing: 'border-box'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          paddingRight: '4rem'
                        }}
                      >
                        <img src={s1} width={100} alt="img" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 'clamp(40px,5vw,64px)',
                          fontWeight: 275,
                          fontStyle: 'italic',
                          fontFamily: 'Poppins',
                          lineHeight: '5rem'
                        }}
                      >
                        Who are{' '}
                        <span
                          style={{
                            fontSize: 'clamp(40px,5vw,64px)',
                            fontWeight: 500,
                            fontFamily: 'Poppins',
                            lineHeight: '5rem'
                          }}
                        >
                          we?
                        </span>
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          paddingLeft: '4rem'
                        }}
                      >
                        <img src={s2} width={100} alt="img" />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1rem'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '25px',
                          fontFamily: 'Poppins',
                          fontWeight: 300,
                          width: '77%',
                          marginBottom: '6rem'
                        }}
                      >
                        Welcome to our online rental marketplace - the perfect
                        place to share your stuff or find new treasures that'll
                        bring excitement and joy to your life.
                      </Typography>
                    </Box>
                  </SectionOneContainerStyle>
                </Grid>
                {/* p2 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img
                      src={ab1}
                      width={'100%'}
                      alt="img"
                      style={{ marginTop: '12rem' }}
                    />
                  </Grid>
                )}
              </Grid>

              {/* section 2 */}

              <Grid container>
                {/* p1 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img src={ab2} width={'90%'} alt="img" />
                  </Grid>
                )}
                {/* p2 */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          padding: '1rem 4rem',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 'clamp(40px,4vw,54px)',
                            fontWeight: 275,
                            fontStyle: 'italic',
                            fontFamily: 'Poppins'
                          }}
                        >
                          Letting go has never{' '}
                          <span
                            style={{
                              fontSize: 'clamp(40px,4vw,54px)',
                              fontWeight: 500,
                              fontFamily: 'Poppins',
                              textAlign: 'start'
                            }}
                          >
                            been so easy
                          </span>
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: 'clamp(15px,4vw,25px)',
                          fontWeight: 300,
                          width: '77%',
                          marginBottom: '5rem'
                        }}
                      >
                        Discovering new things has never been so exciting and
                        never been so exciting! We're here to help you make the
                        most out of your belongings and your life.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* section 3 */}
              <Grid container>
                {/* p1 */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          padding: '1rem 4rem',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 'clamp(40px,4vw,54px)',
                            fontWeight: 275,
                            fontStyle: 'italic',
                            fontFamily: 'Poppins'
                          }}
                        >
                          Our platform connects you{' '}
                          <span
                            style={{
                              fontSize: 'clamp(40px,4vw,54px)',
                              fontWeight: 500,
                              fontFamily: 'Poppins',

                              textAlign: 'start'
                            }}
                          >
                            with a community of renters worldwide
                          </span>
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: 'clamp(15px,4vw,25px)',
                          fontWeight: 300,
                          width: '77%',
                          marginBottom: '5rem'
                        }}
                      >
                        Are you looking for the items to share? Whether it's a
                        camera, a bike, a designer handbag, or the latest tech
                        gadget, we make it easy for you to rent out your items
                        and earn extra cash.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* p2 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img src={ab3} width={'90%'} alt="img" />
                  </Grid>
                )}
              </Grid>

              {/* section 4 */}
              <Grid container>
                {/* p1 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img src={ab4} width={'90%'} alt="img" />
                  </Grid>
                )}

                {/* p2 */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          padding: '1rem 4rem',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 'clamp(40px,4vw,54px)',
                            fontWeight: 275,
                            fontStyle: 'italic',
                            fontFamily: 'Poppins'
                            // lineHeight: '5rem'
                          }}
                        >
                          But we're not just about{' '}
                          <span
                            style={{
                              fontSize: 'clamp(40px,4vw,54px)',
                              fontWeight: 500,
                              fontFamily: 'Poppins',
                              textAlign: 'start'
                            }}
                          >
                            helping you earn money
                          </span>
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: 'clamp(15px,4vw,25px)',
                          fontWeight: 300,
                          width: '77%',
                          marginBottom: '5rem'
                        }}
                      >
                        we're also dedicated to providing a curated list of
                        items you'll love to rent. In addition, our team is
                        constantly finding new ways to make renting even easier,
                        including at-home pickups, delivery, and a user-friendly
                        platform that's easy to navigate. 
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* section 5 */}
              <Grid container>
                {/* p1 */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: matches ? null : '11%'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          padding: '1rem 4rem',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 'clamp(40px,4vw,54px)',
                            fontWeight: 275,
                            fontStyle: 'italic',
                            fontFamily: 'Poppins'
                          }}
                        >
                          We are the place whenever{' '}
                          <span
                            style={{
                              fontSize: 'clamp(40px,4vw,54px)',
                              fontWeight: 500,
                              fontFamily: 'Poppins',
                              textAlign: 'start'
                            }}
                          >
                            you're looking to rent out your belongings
                          </span>
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: 'clamp(15px,4vw,25px)',
                          fontWeight: 300,
                          width: '77%',
                          marginBottom: '5rem'
                        }}
                      >
                        So, whether you're looking to rent out your belongings
                        or discover new things to try, we're here to help you
                        make the most out of every moment. Say hello to new
                        experiences and adventures, and start renting with us
                        today!
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* p2 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img src={ab5} width={'90%'} alt="img" />
                  </Grid>
                )}
              </Grid>

              {/* section 6 */}
              <Box sx={{ textAlign: 'center', marginTop: '8rem' }}>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 300,
                    fontFamily: 'Poppins',
                    fontStyle: 'italic'
                  }}
                >
                  Are you looking
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 500,
                    fontFamily: 'Poppins'
                  }}
                >
                  to borrow something?
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,50px)',
                    fontWeight: 300,
                    fontFamily: 'Poppins'
                  }}
                >
                  Here’s how
                </Typography>
              </Box>

              {/* section 7 */}
              <Box
                sx={{
                  marginTop: '5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: '1rem'
                }}
              >
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc1} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Be respectful
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    Treat others in the community with kindness and respect.
                    Avoid using offensive language or making personal attacks.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc2} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Follow the law
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    Make sure all transactions on the marketplace comply with
                    all applicable laws and regulations.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc3} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Provide accurate information
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    Accurately describe the items you are selling and ensure
                    that all photos and descriptions are truthful and
                    up-to-date.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc4} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Protect personal information
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    Do not share personal information in the marketplace, such
                    as phone numbers or addresses.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc2} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Avoid scams
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    Be cautious of offers that seem too good to be true, and
                    report any suspicious activity to the marketplace staff.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '22rem',
                    padding: '53px 32px 53px 32px',
                    textAlign: 'center',
                    boxShadow: '-4px 17px 94px 16px rgba(0, 0, 0, 0.08)',
                    borderRadius: '29px'
                  }}
                >
                  <img src={aboutc5} alt="img" width={110} />
                  <Typography
                    sx={{
                      fontSize: '23px',
                      fontWeight: 700,
                      fontFamily: 'Poppins',
                      marginTop: '1rem'
                    }}
                  >
                    Solve disputes peacefully
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Poppins',
                      color: '#6C6C6C',
                      marginTop: '1rem'
                    }}
                  >
                    If a dispute arises, try to resolve it amicably with the
                    other party. If you need assistance, our support team is
                    here to help.
                  </Typography>
                </Box>
              </Box>

              {/* section 8 */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginTop: '8rem'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 'clamp(20px,5vw,20px)',
                    fontWeight: 300,
                    fontFamily: 'Poppins',
                    width: '80%'
                  }}
                >
                  By following these guidelines, we hope to maintain a
                  supportive and professional community where members can feel
                  confident buying and selling goods and services.
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(20px,5vw,24px)',
                    fontWeight: 275,
                    fontFamily: 'Poppins',
                    marginTop: '3rem',
                    fontStyle: 'italic'
                  }}
                >
                  Thank you for being a part of Borrowed.io!
                </Typography>
              </Box>
            </MainContainer>
          </Container3>
        </Container2>
      </Container>
      <AppFooterv2 />
    </>
  );
}

export default Herov3;
