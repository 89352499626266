import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import one from '../../../assets/images/11.png';
import two from '../../../assets/images/12.png';
import three from '../../../assets/images/13.png';
import four from '../../../assets/images/14.png';
import w1 from '../../../assets/images/w1.png';
import w2 from '../../../assets/images/w2.png';
import w3 from '../../../assets/images/w3.png';
import w4 from '../../../assets/images/w4.png';

function HowBorrowedWorks() {
  const [state, setState] = React.useState('renters');
  const ForRenters = () => (
    <Box
      sx={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
        padding: '0rem 2rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          height: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          borderRadius: '9px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            1
          </Typography>
          <img src={four} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Search
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Browse listings for keyword or category
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            2
          </Typography>
          <img src={three} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Reserve
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Select an item and the dates you’d like to use it
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            3
          </Typography>
          <img src={two} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Enjoy
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Pickup your rental item then have fun!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            4
          </Typography>
          <img src={one} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Return
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Return the item to the owner the leave a review
          </Typography>
        </Box>
      </Box>
    </Box>
  );
  const ForOwners = () => (
    <Box
      sx={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
        padding: '0rem 2rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            1
          </Typography>
          <img src={w3} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            List
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Browse listings for keyword or category
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            2
          </Typography>
          <img src={w2} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Approve
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Select an item and the dates you’d like to use it
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            3
          </Typography>
          <img src={w4} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Rent
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Pickup your rental item then have fun!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '20rem',
          textAlign: 'center',
          padding: '20px 10px 10px 10px',
          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
          borderRadius: '9px',
          height: '20rem'
        }}
      >
        <Box>
          <Typography
            sx={{
              background: '#86BA5E',
              borderRadius: '69px',
              width: '2rem',
              height: '2rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              fontSize: '1.5rem',
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}
          >
            4
          </Typography>
          <img src={w1} alt="img" style={{ width: '4.2rem' }} />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              lineHeight: '54px',
              fontWeight: '700',
              paddingTop: '1rem'
            }}
          >
            Earn
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
            Return the item to the owner the leave a review
          </Typography>
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      <Box sx={{ background: '#F8FFF5', paddingTop: '9rem' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: '275',
              fontStyle: 'italic',
              fontSize: 'clamp(35px,5vw,60px)',
              marginBottom: '4rem'
            }}
          >
            How Borrowed
            <br />
            <span
              style={{
                fontWeight: '500',
                fontFamily: 'Poppins',
                fontSize: 'clamp(35px,5vw,60px)',
                fontStyle: 'normal'
              }}
            >
              works?
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography
            sx={{ fontFamily: 'Poppins', width: '50%', fontSize: '1.3rem' }}
          >
            Our app makes it simple to list your items and set your own rental
            prices. You can also browse through a variety of items.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '3rem'
          }}
        >
          <Box
            sx={{
              padding: '1rem 1rem 1rem 1rem',
              borderRadius: '68px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              backgroundColor: 'white',
              fontSize: '20px',
              boxShadow: '0px 5px 70px 1px rgba(0, 0, 0, 0.2)',
              flexWrap: 'wrap'
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: '#191A15',
                backgroundColor: state === 'renters' ? '#235E4F' : 'white',
                color: state === 'renters' ? 'white' : 'black',
                borderRadius: '30px',
                boxShadow: 'none',
                height: '2.8rem',
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontWeight: '400',
                padding: '6px 25px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#235E4F',
                  color: 'white'
                }
              }}
              onClick={() => setState('renters')}
            >
              For Renters
            </Button>
            <Button
              variant="contained"
              sx={{
                color: '#191A15',
                backgroundColor: state === 'owners' ? '#235E4F' : 'white',
                color: state === 'owners' ? 'white' : 'black',
                boxShadow: 'none',
                fontSize: '20px',
                fontWeight: '400',
                borderRadius: '30px',
                height: '2.8rem',
                fontFamily: 'Poppins',
                padding: '6px 25px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#235E4F',
                  color: 'white'
                }
              }}
              onClick={() => setState('owners')}
            >
              For Owners
            </Button>
          </Box>
        </Box>
        {state === 'renters' ? <ForRenters /> : <ForOwners />}
      </Box>
    </>
  );
}

export default HowBorrowedWorks;
