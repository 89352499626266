import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';

const SellerReviews = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{ paddingBottom: '1rem', marginTop: '2rem' }}
            >
              Sellers Reviews
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4vw' }}>
            <Box>
              <Rating
                name="read-only"
                value={3}
                sx={{ color: '#808000' }}
                readOnly
              />
              <Typography
                sx={{ fontSize: '16px', color: '#A0A0A0', marginBottom: '6px' }}
              >
                04/02/2022
              </Typography>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 86, height: 86 }}
                  src={require('../assets/images/client1.png')}
                />
                <Box>
                  <Typography variant="subtitle1">Great experience</Typography>
                  <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
                    Rentee
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Rating
                name="read-only"
                value={3}
                sx={{ color: '#808000' }}
                readOnly
              />
              <Typography
                sx={{ fontSize: '16px', color: '#A0A0A0', marginBottom: '6px' }}
              >
                04/02/2022
              </Typography>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 86, height: 86 }}
                  src={require('../assets/images/client1.png')}
                />
                <Box>
                  <Typography variant="subtitle1">Great experience</Typography>
                  <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
                    Rentee
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            width: '74%',
            marginTop: '4rem',
            textAlign: 'end',
            textDecoration: 'underline'
          }}
        >
          See All
        </Typography>
      </Box>
    </>
  );
};

export default SellerReviews;
