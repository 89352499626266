import { Box } from '@mui/material';
import React from 'react';
import CustomLink from './CustomLink';
import { Link } from 'react-router-dom';

const FooterLinks = ({ footerData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'start' }
        // mb: '20px',
        // mr: { xs: '10px', md: '60px', lg: '50px' },
        // '&:not(:lastChild)': { xs: '80px', md: '60px', lg: '50px' }
      }}
    >
      {footerData.map((data, mkey) => (
        <Link
          to={data.link}
          style={{
            color: '#fff',
            textDecoration: 'none',
            m: '3px'
          }}
          key={mkey}
        >
          <CustomLink
            index={data.id}
            link={data.link}
            name={data.name}
            title={data.title}
          />
        </Link>
      ))}
    </Box>
  );
};

export default FooterLinks;
