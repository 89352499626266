import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import local1 from '../assets/images/local1.png';
import local2 from '../assets/images/local2.png';
import local3 from '../assets/images/local3.png';
import local4 from '../assets/images/local4.png';
import local5 from '../assets/images/local5.png';
import local6 from '../assets/images/local6.png';
import nextarrow from '../assets/images/nextarrow.png';
import useWindowSize from '../hooks/dimension';

let matches;
const ImageSlider = () => {
  const size = useWindowSize();
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.down('md'));

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          dots: true,
          swipeToSlide: true,
          arrows: false
        }
      }
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick, slideCount, currentSlide } = props;
    const slidedPerView = 1;
    return (
      <img
        src={nextarrow}
        className={className}
        style={{
          ...style,
          display:
            currentSlide + slidedPerView == slideCount ? 'none' : 'block',
          fontSize: '1.5rem',
          color: '#1E1E1E',
          width: '51px',
          height: '51px'
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <img
        src={nextarrow}
        className={className}
        style={{
          ...style,
          width: '51px',
          height: '51px',
          display: currentSlide > 0 ? 'block' : 'none',
          fontSize: '1.5rem',
          transform: 'scaleX(-1)',
          color: '#1E1E1E'
        }}
        onClick={onClick}
      />
    );
  }
  const itemData = [
    {
      id: 1,
      img: local1
    },
    {
      id: 2,
      img: local2
    },
    {
      id: 3,
      img: local3
    },
    {
      id: 4,
      img: local4
    },
    {
      id: 5,
      img: local5
    },
    {
      id: 6,
      img: local6
    }
  ];

  return (
    <>
      <Box
        sx={{ textAlign: 'center', marginRight: '2rem', marginBottom: '2rem' }}
      >
        <Slider {...settings}>
          {itemData.map((value) => {
            return (
              <Box
                key={value.id}
                sx={{
                  display: 'flex !important',
                  justifyContent: 'center'
                }}
              >
                <img
                  src={`${value.img}`}
                  alt="img"
                  style={
                    matches
                      ? { width: '50%', border: 'solid yellow' }
                      : {
                          width: '82%',
                          minWidth: '24rem',
                          maxWidth: '24rem'
                        }
                  }
                />
              </Box>
            );
          })}
        </Slider>
      </Box>
    </>
  );
};

export default ImageSlider;
