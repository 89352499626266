import auth0 from 'auth0-js';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsDialog, setUserAuthError } from '../redux/slices/UserSlice';

let dispatch;
export default class Auth {
  constructor(history) {
    dispatch = new useDispatch();
    this.history = history;
    let callbackURL = `${window.location.origin}/callback`;
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: callbackURL,
      responseType: 'token id_token'
      //   scope: "openid email",
    });
  }

  login = (username, password) => {
    this.auth0.login(
      { realm: 'Username-Password-Authentication', username, password },
      (err, authResult) => {
        if (err) {
          dispatch(setUserAuthError(err.description));
          //   message.error("Incorrect Email or Password", 10);
        } else {
          console.error('authResult', authResult);
        }
      }
    );
  };

  signup = (email, password, firstname, lastname, dob, name) => {
    this.auth0.signup(
      {
        connection: 'Username-Password-Authentication',
        username: lastname,
        email: email,
        password: password
      },
      (err) => {
        if (err) {
          console.log(err);
          return alert('Something went wrong: ' + err);
        } else {
          axios
            .post(`${process.env.REACT_APP_API_URL}/user`, {
              firstName: firstname,
              lastName: lastname,
              dob: dob,
              email: email
            })
            .then((response) => {
              console.log('Response = ', response);
              if (response) dispatch(setIsDialog([email, password, true]));
            })
            .catch((error) => console.log('Error =', error));
        }
      }
    );
  };

  handleAuth = async (callback) => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const expiresAt = JSON.stringify(
          authResult.expiresIn * 1000 + new Date().getTime()
        );

        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);

        callback(true);
      } else {
        callback(false);
      }

      if (err && err.code) {
        callback(false);
      }
    });
  };

  setSession = async (authResult) => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    // this.history.push("/");
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    return true;

    // this.props.navigate("/");
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

    // console.log(expiresAt, 'expiresAt');
    return new Date().getTime() < expiresAt;
  };

  logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.userProfile = null;

    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: `${process.env.REACT_APP_BASE_URL}/callback`
    });
  };

  getAccessToken = () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  };

  getProfile = (callback) => {
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      callback(profile);
    });
  };
}
