import { Box } from '@mui/material';
import React from 'react';
import CustomLink from './CustomLink';
import { Link } from '@mui/material';

const FooterLinks = ({ footerData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: '20px',
        mr: { xs: '10px', md: '60px', lg: '50px' },
        '&:not(:lastChild)': { xs: '80px', md: '60px', lg: '50px' }
      }}
    >
      {footerData.map((data, mkey) => (
        <Link
          href={data.link}
          sx={{ color: '#fff', textDecoration: 'none', m: '3px' }}
          key={mkey}
        >
          <CustomLink index={data.id} link={data.link} name={data.name} />
        </Link>
      ))}
    </Box>
  );
};

export default FooterLinks;
