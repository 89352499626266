import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  status: 'idle',
  user: null,
  isAuthenticated: false,
  error: null,
  isDialog: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuthenticationStatus: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserAuthError: (state, action) => {
      state.error = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsDialog: (state, action) => {
      console.log('=====asdfsaf=====', action);
      state.isDialog = action.payload;
    }
  }
});
export const {
  setUser,
  setUserAuthenticationStatus,
  setUserAuthError,
  setIsDialog
} = userSlice.actions;

export default userSlice.reducer;

export const userSelector = (state) => state.user;
