import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import phone1 from '../../../assets/images/phone1.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function AllTheBenefits() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{ paddingTop: '10rem', background: '#F8FFF5' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: '275',
                fontStyle: 'italic',
                fontSize: 'clamp(35px,5vw,60px)',
                marginBottom: '4rem'
              }}
            >
              All the
              <br />
              <span
                style={{
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  fontSize: 'clamp(35px,5vw,60px)',
                  fontStyle: 'normal'
                }}
              >
                Benefits!
              </span>
            </Typography>
            <Box>
              <Typography
                sx={{
                  color: '#191A15',
                  fontSize: 'clamp(1rem,5vw,1.5rem)',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  lineHeight: '40px',
                  marginBottom: '1rem'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: '#188569',
                    marginRight: '1rem',
                    fontSize: '2rem'
                  }}
                />
                Free Support
              </Typography>
              <Typography
                sx={{
                  color: '#191A15',
                  fontSize: 'clamp(1rem,5vw,1.5rem)',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  lineHeight: '40px',
                  marginBottom: '1rem'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: '#188569',
                    marginRight: '1rem',
                    fontSize: '2rem'
                  }}
                />
                Borrow protection
              </Typography>
              <Typography
                sx={{
                  color: '#191A15',
                  fontSize: 'clamp(1rem,5vw,1.5rem)',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  lineHeight: '40px',
                  marginBottom: '1rem'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: '#188569',
                    marginRight: '1rem',
                    fontSize: '2rem'
                  }}
                />
                Rating system
              </Typography>
              <Typography
                sx={{
                  color: '#191A15',
                  fontSize: 'clamp(1rem,5vw,1.5rem)',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  lineHeight: '40px',
                  marginBottom: '1rem'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: '#188569',
                    marginRight: '1rem',
                    fontSize: '2rem'
                  }}
                />
                1 to 1 chat
              </Typography>
              <Typography
                sx={{
                  color: '#191A15',
                  fontSize: 'clamp(1rem,5vw,1.5rem)',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  lineHeight: '40px',
                  marginBottom: '1rem'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: '#188569',
                    marginRight: '1rem',
                    fontSize: '2rem'
                  }}
                />
                Exchange security
              </Typography>
            </Box>
          </Box>
        </Grid>
        {matches ? null : (
          <Grid item xs={12} md={6}>
            <img
              src={phone1}
              alt="img"
              style={{ marginTop: '1rem', width: 'clamp(20rem,100%,37rem)' }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AllTheBenefits;
