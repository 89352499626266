import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Auth from '../../../auth/Auth';
import { setUserAuthenticationStatus } from '../../../redux/slices/UserSlice';

const Callback = () => {
  const auth = new Auth();
  const dispatch = new useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    auth.handleAuth(navigateToHomeScreen);
  }, []);

  const navigateToHomeScreen = (status) => {
    if (status) {
      dispatch(setUserAuthenticationStatus(true));
      navigate('/home');
    } else {
      dispatch(setUserAuthenticationStatus(false));
      navigate('/', { replace: true });
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flex={'1'}
      alignSelf={'center'}
    >
      <ClipLoader
        color={'green'}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Box>
  );
};

export default Callback;
