import { styled } from '@mui/material/styles';

export const MainContainer = styled('div')(() => ({
  marginTop: '5rem'
}));

export const AccordianContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export const AccordianHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
export const Expand = styled('div')(() => ({
  paddingTop: '1rem'
}));

export const Text = styled('div')(({ matchesSM }) => ({
  fontSize: 'clamp(15px,5vw,30px)',
  lineHeight: matchesSM ? '2rem' : '3rem',
  fontWeight: '600',
  fontFamily: 'Poppins'
}));

export const Accordian = styled('div')(({ matchesSM }) => ({
  width: 'clamp(18rem,100%,70%)',
  backgroundColor: 'white',
  border: '2px solid #96B964',
  borderRadius: '14px',
  boxSizing: 'border-box',
  boxShadow: '0px 6px 16px rgba(74, 58, 255, 0.19)',
  padding: matchesSM ? '0rem 1rem' : '2rem 3rem',
  margin: matchesSM ? '0rem 1rem' : null
}));

export const AccordianBodyHeading = styled('div')(() => ({
  fontFamily: 'Poppins',
  fontSize: 'clamp(15px,5vw,25px)',
  fontWeight: '400'
}));

export const AccordianBodyPara = styled('div')(() => ({
  fontSize: '20px',
  fontWeight: '400'
}));
