import FilterListIcon from '@mui/icons-material/FilterList';
import { Avatar, Box, Button, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';
import Auth from '../auth/Auth';
import { Link } from 'react-router-dom';

const Herov2 = ({ route_profile }) => {
  const auth = new Auth();
  const [userData, setUserData] = useState(null);
  const [value, setValue] = React.useState(0);

  const getUserData = (profile) => {
    setUserData(profile);
  };

  useEffect(() => {
    auth.getProfile(getUserData);
  }, []);

  return (
    <Box
      sx={
        route_profile
          ? {
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
              flexWrap: 'wrap',
              marginTop: '5rem'
            }
          : {
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
              marginTop: '5rem'
            }
      }
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginLeft: '3rem'
        }}
      >
        <Avatar alt={userData?.name} src={userData?.picture} />
        <Box>
          <Typography variant="h5">{userData?.nickname}</Typography>
          {route_profile ? (
            <>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Rating
                  sx={{ color: '#808000' }}
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <Typography variant="body1" sx={{ color: 'gray' }}>
                  {value < 1 ? 'No' : value} reviews
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
              You have 0 notifications
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={
          route_profile
            ? {
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                marginRight: '3rem',
                flexWrap: 'wrap',
                marginLeft: '3rem'
              }
            : {
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                marginRight: '3rem',
                flexWrap: 'wrap'
              }
        }
      >
        {route_profile ? (
          <>
            <Link to="/edit-profile" style={{ textDecoration: 'none' }}>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'capitalize',
                    width: '7rem',
                    color: '#1E1E1E',
                    fontWeight: '400'
                  }}
                >
                  Edit Profile
                </Button>
              </Box>
            </Link>
            {/* <Box>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'capitalize',
                  width: '10rem',
                  fontWeight: '400',
                  color: '#1E1E1E'
                }}
              >
                View Public Profile
              </Button>
            </Box> */}
          </>
        ) : null}
        <Box>
          <Link to="/list-an-item" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={
                route_profile
                  ? {
                      textTransform: 'capitalize',
                      width: '7rem',
                      fontWeight: '400',
                      '&:hover': {
                        backgroundColor: '#34530D'
                      }
                    }
                  : {
                      textTransform: 'capitalize',
                      borderRadius: '37px',
                      backgroundColor: '#34530D',
                      fontWeight: '400',
                      '&:hover': {
                        backgroundColor: '#34530D'
                      }
                    }
              }
            >
              list an item
            </Button>
          </Link>
        </Box>

        {route_profile ? null : (
          <Box>
            <Button
              variant="outlined"
              sx={{
                textTransform: 'capitalize',
                borderRadius: '37px',
                borderColor: '#A0A0A0',
                fontWeight: '400',
                color: '#A0A0A0'
              }}
            >
              filter
              <span style={{ padding: '0px 10px' }}>
                <FilterListIcon fontSize="small" />
              </span>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Herov2;
