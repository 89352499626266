import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { logo } from '../assets/images/AmazonS3/index';
import FooterLinks from './FooterLinks';

const styles = (theme) => ({
  root: {
    background: 'linear-gradient(to right bottom, #000000 40%,  #808000)'
  },

  footerText: {
    color: 'white',

    ...theme.typography.footer
  },
  footerTitle: {
    color: 'white',
    marginBottom: theme.spacing(1)
  },

  footerContent1: {
    marginBottom: theme.spacing(5)
  },

  logoFooterStyle: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginTop: 30,

      width: '100%',
      paddingBottom: 20
    },

    display: 'flex',
    justifyContent: 'flex-start',

    [theme.breakpoints.up('xs')]: {
      paddingLeft: 20,
      paddingTop: 20,
      marginBottom: 10
    },

    rowContainer: {
      marginBottom: theme.spacing(4)
    }
  }
});

function AppFooter() {
  const footerDataFirst = [
    {
      id: 1,
      name: 'Address Here',
      link: '#'
    },
    {
      id: 2,
      name: 'City',
      link: '#'
    },
    {
      id: 3,
      name: 'Country',
      link: '#'
    },
    {
      id: 4,
      name: 'Phone Number',
      link: '#'
    },
    // {
    //   id: 4,
    //   name: 'About',
    //   link: '#'
    // },

    {
      id: 5,
      name: 'Fax Number',
      link: '#'
    },

    {
      id: 6,
      name: 'Email Address',
      link: '#'
    }
  ];

  const footerDataSecond = [
    {
      id: 1,
      name: 'Policy Center',
      link: '#'
    },
    {
      id: 2,
      name: 'Privacy',
      link: '/privacy-policy'
    },
    {
      id: 3,
      name: 'Prohibited Items',
      link: '#'
    },
    {
      id: 4,
      name: 'Tax',
      link: '#'
    }
  ];

  const footerDataThird = [
    {
      id: 1,
      name: 'Our Service',
      link: '#'
    },
    {
      id: 2,
      name: 'Sells',
      link: '#'
    },
    {
      id: 3,
      name: 'Buys',
      link: '#'
    },
    {
      id: 4,
      name: 'Rents',
      link: '#'
    }
  ];

  const footerDataFourth = [
    {
      id: 1,
      name: 'Company',
      link: '#'
    },
    {
      id: 2,
      name: 'About Us',
      link: '/about-us'
    },
    {
      id: 3,
      name: 'Our Team',
      link: '#'
    },
    {
      id: 4,
      name: 'Career',
      link: '#'
    },
    {
      id: 5,
      name: 'Our Mission',
      link: '#'
    }
  ];

  const footerDataFifth = [
    {
      id: 1,
      name: 'Support',
      link: '#'
    },
    {
      id: 2,
      name: 'Contact Us',
      link: '/contact-us'
    },
    {
      id: 3,
      name: 'Help Center',
      link: '#'
    },
    {
      id: 4,
      name: 'Seller Protection',
      link: '#'
    },
    {
      id: 5,
      name: 'Buyer Protection',
      link: '#'
    }
  ];

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right bottom, #000000 40%,  #808000)',
        height: '',
        color: '#fff',
        p: '40px 30px',
        pr: '90px'
      }}
    >
      <Box mb="15px" component="img" src={logo} alt="logo" width={150} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: {
            xs: 'space-between',
            md: 'space-between',
            xl: 'space-between'
          },
          flexWrap: 'wrap'
        }}
      >
        <FooterLinks footerData={footerDataFirst} />
        <FooterLinks footerData={footerDataSecond} />
        <FooterLinks footerData={footerDataThird} />

        <FooterLinks footerData={footerDataFourth} />
        <FooterLinks footerData={footerDataFifth} />
      </Box>
    </Box>
  );
}

export default withStyles(styles)(AppFooter);
