import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/UserSlice';
// import counterReducer from '../features/counter/counterSlice'

export const store = configureStore({
  reducer: {
    user: userSlice
  }
  // other options e.g middleware, go here
});
