import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './routes';
import theme from './theme';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline enableColorScheme />
        <Routes />
        {/* <Component {...props} /> */}
      </ThemeProvider>
    </>
  );
}

export default App;
