import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AppFooterv2 from '../../../components/AppFooterv2';
import NavBarV2 from '../../../components/NavBarV2';
import { BannerContainer } from './Style';

function Blog() {
  const [blogArticles, setBlogArticles] = useState('');
  const [SingleBlog, setSingleBlog] = useState('');

  const getAllBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/blog`)
      .then((response) => {
        if (response) {
          const remainingCards = response.data.slice(1, response.data.length);
          setSingleBlog(response.data[0]);
          setBlogArticles(remainingCards);
        }
      })
      .catch((error) => console.log('Error =', error));
  };

  useMemo(() => {
    // componentWillMount events
    getAllBlogs();
  }, []);

  return (
    <>
      <NavBarV2 />

      <Box sx={{ marginTop: '5rem' }} />
      {/* hero image */}
      <BannerContainer>
        <Typography variant="h4" sx={{ color: 'white', fontFamily: 'Poppins' }}>
          Blog
        </Typography>
        <Typography
          sx={{
            color: 'white',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontFamily: 'Poppins',
            textAlign: 'center'
          }}
        >
          Discover practical insights and ideas to transition towards a circular
          economy and drive positive change
        </Typography>
      </BannerContainer>

      {/* Cards */}
      {blogArticles ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6rem 0rem'
          }}
        >
          <Box
            sx={{
              width: '60%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '2rem'
            }}
          >
            {/* Big card */}
            {SingleBlog && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end'
                }}
              >
                <img
                  src={SingleBlog?.heroImage}
                  alt="img"
                  style={{
                    width: 'clamp(19rem, 100%, 82%)',
                    borderRadius: '20px'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    padding: '2rem 2rem',
                    width: 'clamp(21rem,100%,49%)'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 'clamp(9px,1rem,1vw)',
                      color: 'white',
                      fontFamily: 'Poppins'
                    }}
                  >
                    Feature
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: 'clamp(12px,12vw,2vw)'
                    }}
                  >
                    <Link
                      to={`/blog/${SingleBlog?.id}`}
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        cursor: 'pointer'
                      }}
                    >
                      {SingleBlog?.title}
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 'clamp(9px,1rem,1vw)',
                      color: 'white',
                      fontFamily: 'Poppins'
                    }}
                  >
                    {SingleBlog?.publishDate}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Remaining cards */}
            {blogArticles &&
              blogArticles
                .filter((article) => !article.featured)
                .map((value) => {
                  return (
                    <Box key={value.id}>
                      <Link
                        to={`/blog/${value.id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card
                          sx={{
                            width: '19rem',
                            height: '20rem',
                            cursor: 'pointer',
                            '&:hover': {
                              transition: '.4s all',
                              transform: 'scale(1.02)'
                            }
                          }}
                        >
                          <CardMedia
                            sx={{ height: '10rem' }}
                            image={value.heroImage}
                            title={value.title}
                          />
                          <CardContent>
                            <Typography
                              variant="body2"
                              gutterBottom
                              component="div"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Feature
                            </Typography>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                              sx={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {value.title}
                            </Typography>

                            <Typography
                              variant="body2"
                              gutterBottom
                              component="div"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {value.publishDate}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Link>
                    </Box>
                  );
                })}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LinearProgress sx={{ width: '15%', color: '#86BB5E' }} />
        </Box>
      )}

      <AppFooterv2 />
    </>
  );
}

export default Blog;
