import { Lock } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as yup from 'yup';
import {
  AppleIcon,
  FacebookIcon,
  GmailIcon,
  signupCover
} from '../../../assets/images/AmazonS3/index';
import BorrowedTextLogo from '../../../assets/images/BorrowedTextLogo.png';
import logo from '../../../assets/images/borrowLogo.png';
import Auth from '../../../auth/Auth';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/slices/UserSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

const minPass = 8;
let matches;
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),

  password: yup
    .string('Password Required')
    .required('Password Required')
    .min(
      minPass,
      ({}) =>
        `Minimum ${minPass} characters, 1 Capital letter & one symbol required`
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      `Minimum ${minPass} characters, 1 Capital letter & one symbol required`
    ),
  confirmPassword: yup
    .string('pass')
    .required('Password Match')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const styles = (theme) => ({
  buttonStyle: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.up('sm')]: {
      width: '80%'
    },

    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: theme.spacing(15)
  }
});

function SignupSide(props) {
  const { state } = useLocation();
  const auth = new Auth();
  const { isDialog } = useSelector(userSelector);
  const [open, setOpen] = React.useState(false);
  const [initiateLogin, setInitiateLogin] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.down('md'));

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      dob: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('🚀 ~ file: Signup ~ Side ~ values:', values);
      auth.signup(
        values.email,
        values.password,
        values.firstname,
        values.lastname,
        values.dob
      );
    }
  });

  const IsDialogOpen = () => {
    if (isDialog.length) {
      const [email, password, value] = isDialog;
      setOpen(value);
    }
  };

  const triggerLogin = () => {
    if (isDialog.length) {
      const [email, password, value] = isDialog;
      auth.login(email, password);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (state?.inputemail) formik.setFieldValue('email', state.inputemail);
  }, [state]);

  useEffect(() => {
    IsDialogOpen();
  }, [isDialog]);

  useEffect(() => {
    triggerLogin();
  }, [initiateLogin]);

  return (
    <>
      <Box
        sx={{
          paddingTop: '1rem',
          paddingLeft: matches ? null : '2rem',
          textAlign: matches && 'center'
        }}
      >
        <Link to={'/'}>
          <Box component={'img'} src={logo} alt="logo" width={40} />
          <img
            src={BorrowedTextLogo}
            width={90}
            style={{ marginLeft: '6px' }}
            alt={'text'}
          />
        </Link>
      </Box>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            flexDirection={'column'}
            sx={{ display: { xs: 'none', sm: 'flex', marginTop: '2rem' } }}
          >
            {matches ? null : (
              <Box
                component={'img'}
                src={signupCover}
                alt="logo"
                sx={{ width: '50vw' }}
              />
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{ boxShadow: 'none' }}
        >
          <Box
            sx={{
              margin: matches ? '64px 2rem 64px 2rem' : '64px 5rem 64px 10rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '2rem'
              }}
            >
              <Typography
                sx={{
                  color: '#565601',
                  fontWeight: '400',
                  fontSize: '31.25px'
                }}
                variant={'h6'}
              >
                Sign Up
              </Typography>
              <Typography sx={{ color: '#A0A0A0', fontSize: '20px' }}>
                Welcome to the world of rent!
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                sx={{ backgroundColor: '#E3E3E3' }}
                id="firstname"
                placeholder="First Name"
                name="firstname"
                autoComplete="firstname"
                autoFocus
                value={formik.values.firstname}
                onChange={formik.handleChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                sx={{ backgroundColor: '#E3E3E3' }}
                id="lastname"
                placeholder="Last Name"
                name="lastname"
                autoComplete="lastname"
                autoFocus
                value={formik.values.lastname}
                onChange={formik.handleChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                type="date"
                sx={{ backgroundColor: '#E3E3E3' }}
                id="dob"
                placeholder="Date of Birth"
                name="dob"
                autoComplete="dob"
                autoFocus
                value={formik.values.dob}
                onChange={formik.handleChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                sx={{ backgroundColor: '#E3E3E3' }}
                id="email"
                placeholder="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: '#565601' }} />
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                sx={{ backgroundColor: '#E3E3E3' }}
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock sx={{ color: '#565601' }} />
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                placeholder="Re-Type Password"
                sx={{ backgroundColor: '#E3E3E3' }}
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />

              <Box
                sx={{
                  mt: 3,
                  mb: '29px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    width: { xs: 150 },
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '20px',
                    '&:hover': {
                      backgroundColor: '#808000'
                    }
                  }}
                >
                  Sign Up
                </Button>
              </Box>

              <Divider
                sx={{
                  '&::before, &::after': {
                    borderColor: '#565601',
                    borderWidth: 2
                  }
                }}
                spacing={1}
              >
                <Typography variant="h6">OR</Typography>
              </Divider>
            </form>

            <Box
              sx={{
                mt: 5,
                mb: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{ color: 'black', marginBottom: '1rem', fontSize: '20px' }}
              >
                {'Already have an Account? Sign In here'}
              </Typography>

              <NavLink to={'/login'} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid #808000',
                    fontWeight: '400',
                    fontSize: '20px',
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: '#808000'
                    }
                  }}
                >
                  Sign In
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You Will Receive a Confirmation Email.
          </DialogContentText>
        </DialogContent>

        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CircularProgress size={25} sx={{ color: '#86BB5E' }} />
          </Box>
        ) : null}
        {isLoading ? null : (
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                setTimeout(() => {
                  setOpen(false);
                  setInitiateLogin(true);
                }, 2000);
              }}
            >
              Done
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

export default withStyles(styles)(SignupSide);
