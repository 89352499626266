import React, { useMemo, useState, useEffect } from 'react';
import NavBarV2 from '../../../components/NavBarV2';
import { Box, Typography } from '@mui/material';
import blog1 from '../../../assets/images/blog1.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import AppFooterv2 from '../../../components/AppFooterv2';
import LinearProgress from '@mui/material/LinearProgress';
// import createDOMPurify from 'dompurify'
// import { JSDOM } from 'jsdom'

// const window = (new JSDOM('')).window
// const DOMPurify = createDOMPurify(window)

function BlogArticle() {
  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.down('sm'));
  let { id } = useParams();
  const [blogArticle, setBlogArticle] = useState('');
  const [OtherArticlesData, setOtherArticles] = useState('');

  const getBlogArticleById = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/blog/${id}`)
      .then((response) => {
        console.log('Response = ', response.data);
        if (response) setBlogArticle(response.data);
      })
      .catch((error) => console.log('Error =', error));
  };

  const getAllBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/blog`)
      .then((response) => {
        if (response) {
          setOtherArticles(response.data);
        }
      })
      .catch((error) => console.log('Error =', error));
  };

  useMemo(() => {
    getBlogArticleById();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id]);

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <NavBarV2 />
      {blogArticle && OtherArticlesData ? (
        <Box
          sx={{
            marginTop: '8rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            border: 'sold red'
          }}
        >
          <Box
            sx={{
              width: matches ? '100%' : '60%',
              textAlign: 'start',
              padding: '0rem 1rem',
              border: 'sold green'
            }}
          >
            <Typography>{blogArticle?.publishDate}</Typography>
          </Box>

          <Box
            sx={{
              padding: '0rem 1rem',
              width: matches ? null : '60%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '2rem'
            }}
          >
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
              {blogArticle.title}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ textTransform: 'capitalize' }}
            >
              {blogArticle?.subtitle}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={blogArticle?.heroImage}
              alt="img"
              style={{ width: 'clamp(17rem, 100%, 60%)', borderRadius: '10px' }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '2rem',
              padding: matches ? '0rem 1rem' : null
            }}
          >
            <Box
              sx={{
                width: matches ? null : '60%',
                padding: matches ? '0rem 1rem' : null,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {/* Subtitle here */}
              <div id="blob">{blogArticle && parse(blogArticle?.content)}</div>
            </Box>
            {/* other articles */}
            <Box
              sx={{
                width: matches ? null : '60%',
                marginTop: '5rem',
                padding: matches ? '0rem 1rem' : null
              }}
            >
              <Typography
                sx={{
                  backgroundColor: '#D9D9D9',
                  padding: '1rem',
                  display: 'inline-block'
                }}
              >
                Other articles
              </Typography>

              {OtherArticlesData &&
                OtherArticlesData.filter((article) => !article.featured).map(
                  (value) => {
                    return (
                      <div key={value?.id}>
                        <Link
                          to={`/blog/${value?.id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '1rem',
                              marginTop: '2rem'
                            }}
                          >
                            <img
                              src={value?.heroImage}
                              alt="img"
                              style={{
                                width: '10rem',
                                height: '8rem',
                                borderRadius: '20px'
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '2rem 0rem'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '10px',
                                  color: 'black',
                                  fontFamily: 'Poppins'
                                }}
                              >
                                FEATURE {value?.featured}
                              </Typography>
                              <Typography
                                sx={{
                                  color: 'black',
                                  fontFamily: 'Poppins',
                                  fontWeight: 'bold',
                                  fontSize: '12px'
                                }}
                              >
                                {value?.title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '10px',
                                  color: 'black',
                                  fontFamily: 'Poppins'
                                }}
                              >
                                {value?.publishDate}
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <hr />
                      </div>
                    );
                  }
                )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LinearProgress sx={{ width: '15%', color: '#86BB5E' }} />
        </Box>
      )}

      <AppFooterv2 />
    </>
  );
}

export default BlogArticle;
