import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, SectionOneContainerStyle } from './Style';
import InputValidatorv2 from '../../components/InputValidatorv2';
import NavBarV2 from '../../components/NavBarV2';
import AppFooterv2 from '../../components/AppFooterv2';
import greenSweater from '../../assets/images/greenSweater.png';
import basketBall from '../../assets/images/basketball.png';
import union1 from '../../assets/images/union1.png';
import union2 from '../../assets/images/union2.png';

function ComingSoon() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <NavBarV2 />
      <Container>
        <Grid container>
          <Grid item xs={12} md={3}>
            <img src={greenSweater} alt="img" style={{ marginTop: '-5rem' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionOneContainerStyle>
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '3rem',
                  padding: '1rem',
                  boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '4rem'
                  }}
                >
                  <img src={union2} width={100} alt="img" />
                </Box>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 275,
                    fontStyle: 'italic',
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  Coming
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  Soon!
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: '4rem'
                  }}
                >
                  <img src={union1} width={100} alt="img" />
                </Box>
              </Box>
              <Box>
                <Box sx={{ width: '100%', margin: 'auto' }}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        padding: '5rem 0rem 2rem 0rem',
                        fontSize: 24,
                        width: '90%',
                        margin: 'auto'
                      }}
                    >
                      Meanwhile, you can register here to get notified when it
                      is ready
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '90%',
                      margin: 'auto'
                    }}
                  >
                    <InputValidatorv2 height={'8vh'} />
                  </Box>
                </Box>
              </Box>
            </SectionOneContainerStyle>
          </Grid>

          <Grid item xs={12} md={3}>
            <img
              src={basketBall}
              alt="img"
              width={matches && '50%'}
              style={{
                float: 'right',
                marginTop: '5rem'
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <AppFooterv2 />
    </>
  );
}

export default ComingSoon;
