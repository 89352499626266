import { Button, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';

const styles = (theme) => ({
  buttonStyle: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('xs')]: {
      width: '50%'
    },

    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },

  inputStyle: {
    [theme.breakpoints.up('xs')]: {
      width: '80%'
    },

    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },

    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  }
});

function InputValidator(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { classes, height } = props;
  const [email, setEmail] = useState('');
  const [emailSentStatus, setEmailSentStatus] = useState({
    status: false,
    type: 'success'
  });
  const navigate = useNavigate();

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && !props.forgotPasswordInput) {
      navigate('/signup', { state: { inputemail: email } });
    }
  };

  const sendPasswordResetEmail = async () => {
    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: email,
          connection: 'Username-Password-Authentication'
        }
      );
      if (response.status === 200) {
        setEmailSentStatus({ status: true, type: 'success' });
        setTimeout(() => {
          setEmailSentStatus({ status: false });
        }, 5000);
      }
    } catch (error) {
      console.log('Error sending password reset email:', error);
      throw error;
    }
  };

  const searchUserByEmail = async (token) => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users-by-email?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (res.data?.length === 0) {
        setEmailSentStatus({ status: true, type: 'warning' });
        setTimeout(() => {
          setEmailSentStatus({ status: false });
        }, 5000);
      } else {
        sendPasswordResetEmail();
      }
    } catch (error) {
      console.log('Error searching for user:', error);
      throw error;
    }
  };

  const getAuthManagementToken = async () => {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      audience: process.env.REACT_APP_AUDIENCE,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };
    await axios
      .post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, data, {
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(async (response) => {
        searchUserByEmail(response.data.access_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetPassword = async () => {
    try {
      if (email) await getAuthManagementToken();
    } catch (error) {
      console.log('Error resetting password:', error);
    }
  };

  const handleButtonSubmit = () => {
    if (props.forgotPasswordInput) return resetPassword();
  };
  return (
    <>
      {emailSentStatus.status && (
        <Alert
          variant="filled"
          style={{
            position: 'absolute',
            top: 100,
            right: 10,
            backgroundColor:
              emailSentStatus.type === 'success' ? '#235E4F' : '#808000'
          }}
        >
          {emailSentStatus.type === 'success'
            ? 'Please check your email for next steps!'
            : 'Email does not exist please signup.'}
        </Alert>
      )}
      <form
        className={classes.container}
        style={matches ? null : { justifyContent: 'center' }}
        noValidate
        autoComplete="off"
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={props?.forgotPasswordInput ? 8 : 9}>
            <input
              type="text"
              style={{
                background: '#FFFFFF',
                height: height,
                border: 'none',
                width: '100%',
                borderRadius: matchesSM ? '12px' : '20px 0 0 20px',
                boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
                padding: '1rem',
                fontFamily: 'Poppins'
              }}
              onChange={(event) => setEmail(event.target.value)}
              onKeyPress={(event) => handleEnterKeyPress(event)}
            />
          </Grid>
          <Grid item xs={12} sm={props?.forgotPasswordInput ? 4 : 3}>
            <Link
              to={!props.forgotPasswordInput && '/signup'}
              state={{ inputemail: email }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                onClick={() => handleButtonSubmit()}
                size="medium"
                variant="contained"
                sx={{
                  fontFamily: 'Poppins',
                  background: '#235E4F',
                  borderRadius: {
                    xs: 3,
                    sm: '0 20px 20px 0'
                  },
                  textTransform: 'capitalize',
                  height: height,
                  width: '100%',
                  fontSize: '1rem',
                  padding: '8px 20px',
                  '&:hover': {
                    backgroundColor: '#235E4F'
                  }
                }}
                style={{
                  alignSelf: 'flex-end'
                }}
              >
                {props?.forgotPasswordInput ? 'Reset Password' : 'Sign Up'}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

InputValidator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InputValidator);
