import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  useMediaQuery,
  useTheme
} from '@mui/material';

import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { logo } from '../assets/images/AmazonS3/index';
import Auth from '../auth/Auth';
import AppBar from './AppBar';
import DrawerComp from './Drawer';
import Toolbar, { styles as toolbarStyles } from './Toolbar';
import Typography from './Typography';

const styles = (theme) => ({
  title: {
    fontSize: 24
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'flex-end'
  },
  left: {
    flex: 1
  },
  leftLinkActive: {
    color: theme.palette.common.black
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rightLink: {
    fontSize: 20,
    color: '#1E1E1E',
    textDecoration: 'none',
    marginLeft: 1,
    '&:hover': {
      color: '#1E1E1E'
    }
  },
  linkSecondary: {
    color: theme.palette.secondary.main
  }
});

const NavbarView = ({ classes }) => {
  const auth = new Auth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutAccount = () => {
    auth.logout();
  };

  const isMatch = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <AppBar sx={{ paddingTop: '2rem' }}>
        <Toolbar>
          <Box display="flex">
            {matches ? null : (
              <NavLink to={'/'}>
                <img src={logo} width={191} alt={'Logo'} />
              </NavLink>
            )}
          </Box>

          {isMatch && auth.isAuthenticated() && (
            <DrawerComp onLogOutPress={logOutAccount} />
          )}

          {/* UI after user successfully logged in  */}
          {isMatch ? null : (
            <>
              {auth.isAuthenticated() && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexGrow: 1
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: '0px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #b9b4b4',
                      borderRadius: '20px',
                      width: '35%',
                      marginRight: '1rem',
                      boxShadow: 'none'
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: '0px' }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  <NavLink className={classes.rightLink} to={'/'}>
                    {'Home'}
                  </NavLink>
                  <NavLink className={classes.rightLink} to={'/'}>
                    {'Items'}
                  </NavLink>
                  <NavLink className={classes.rightLink} to={'/'}>
                    {'Message'}
                  </NavLink>
                  <NavLink className={classes.rightLink} to={'/'}>
                    {'Notifications'}
                  </NavLink>
                  <Typography
                    className={classes.rightLink}
                    style={{
                      cursor: 'pointer',
                      fontSize: '20px'
                    }}
                    onClick={handleClick}
                  >
                    Profile
                  </Typography>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <Link
                      to={'/profile'}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <MenuItem>My Account</MenuItem>
                    </Link>
                    <MenuItem
                      onClick={() => {
                        logOutAccount();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                  {/* <IconButton style={{}}>
                    <ShoppingCartOutlinedIcon />
                  </IconButton> */}
                </Box>
              )}
            </>
          )}

          {/* UI before user logged in  */}
          {!auth.isAuthenticated() && (
            <Box
              sx={{
                display: 'flex',
                gap: '2rem',
                justifyContent: 'flex-end',
                flexGrow: 1
              }}
            >
              <NavLink className={classes.rightLink} to={'/signup'}>
                {'Sign Up'}
              </NavLink>

              <NavLink className={classes.rightLink} to={'/login'}>
                {'Login'}
              </NavLink>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </>
  );
};

export default withStyles(styles)(NavbarView);
