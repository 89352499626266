import React from 'react';
import { Container, Container2, Container3 } from '../Style';
import NavBarV2 from '../../../components/NavBarV2';
import Grid from '@mui/material/Grid';
import {
  MainContainer,
  AccordianContainer,
  Accordian,
  Text,
  Expand,
  AccordianHeader,
  AccordianBodyHeading,
  AccordianBodyPara
} from './Style';
import { faqs, plant, faqBag } from '../../../assets/images/AmazonS3/index';
import { Box, IconButton, Typography } from '@mui/material';
import s1 from '../../../assets/images/union1.png';
import accordactive from '../../../assets/images/accordactive.png';
import accordinactive from '../../../assets/images/accordinactive.png';
import s2 from '../../../assets/images/union2.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppFooterv2 from '../../../components/AppFooterv2';

function FAQs() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
  const [expand, setExpand] = React.useState({
    accord1: false,
    accord2: false,
    accord3: false,
    accord3: false,
    accord5: false,
    accord6: false,
    accord7: false,
    accord8: false,
    accord9: false
  });

  return (
    <>
      <Container>
        <Container2>
          <Container3>
            <NavBarV2 />
            <MainContainer>
              {/* section 1 */}
              <Grid container>
                {/* p1 */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'center',
                        marginTop: '3rem',
                        marginBottom: '3rem',
                        padding: '1rem',
                        boxSizing: 'border-box',
                        width: '55%'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end'
                        }}
                      >
                        <img src={s2} width={'70rem'} alt="img" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 'clamp(40px,7vw,100px)',
                          fontWeight: 500,
                          fontFamily: 'Poppins',
                          lineHeight: '5rem'
                        }}
                      >
                        FAQ
                        <span
                          style={{
                            fontSize: 'clamp(40px,7vw,100px)',
                            fontWeight: 300,
                            fontFamily: 'Poppins',
                            lineHeight: '5rem',
                            fontStyle: 'italic'
                          }}
                        >
                          s
                        </span>
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start'
                        }}
                      >
                        <img src={s1} width={'70rem'} alt="img" />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* p2 */}
                {matches ? null : (
                  <Grid item xs={12} md={6}>
                    <img
                      src={faqs}
                      width={'100%'}
                      alt="img"
                      style={{ marginTop: '2rem', marginLeft: '-6rem' }}
                    />
                  </Grid>
                )}
              </Grid>

              {/* section 2 */}

              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
              >
                {/* 1 */}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>Why Borrowed?</Text>
                      <IconButton
                        onClick={() => setExpand({ accord1: !expand.accord1 })}
                      >
                        {expand.accord1 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord1 && (
                      <Expand>
                        <AccordianBodyHeading>
                          Less clutter, more adventure.
                        </AccordianBodyHeading>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Affordability
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Renting items, such as furniture, appliances, or
                          tools, can be more affordable than buying them
                          outright, especially if you only need them for a short
                          period of time.
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>Convenience</AccordianBodyHeading>
                        <AccordianBodyPara>
                          Renting items eliminates the need to store them when
                          they're not in use or to sell them when you no longer
                          need them.
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Access to high-end items
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Renting items eliminates the need to store them when
                          they're not in use or to sell them when you no longer
                          need them.
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Minimal financial risk
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          When you rent an item, you don't have to worry about
                          its depreciation or market value, since you'll be
                          returning it at the end of the rental period.
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Reduced waste
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Renting items helps reduce waste by making it possible
                          for items to be used by multiple people over time,
                          rather than only being used once and then discarded
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 2 */}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        How do I search for a specific item?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord2: !expand.accord2 })}
                      >
                        {expand.accord2 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord2 && (
                      <Expand>
                        <AccordianBodyPara>
                          You can search for items using our search bar located
                          at the top of our website. Simply type in the item
                          you're looking for and hit enter to see the results.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 3 */}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>How do I rent an item</Text>
                      <IconButton
                        onClick={() => setExpand({ accord3: !expand.accord3 })}
                      >
                        {expand.accord3 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord3 && (
                      <Expand>
                        <AccordianBodyPara>
                          To rent an item, simply select the item you want,
                          select the dates you want to rent it for, and complete
                          the checkout process.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 4 */}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        What is the rental period for items?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord4: !expand.accord4 })}
                      >
                        {expand.accord4 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord4 && (
                      <Expand>
                        <AccordianBodyPara>
                          You can select any number of days during the checkout
                          process. The rental period is based on availability of
                          the item in question. 
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 5*/}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        What forms of payment do you accept?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord5: !expand.accord5 })}
                      >
                        {expand.accord5 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord5 && (
                      <Expand>
                        <AccordianBodyPara>
                          We accept all major credit cards, as well as PayPal
                          and other secure online payment methods. Rental
                          pricing is based on a daily rate.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 6*/}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        Can I rent items if I'm not located in the same country
                        as the owner?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord6: !expand.accord6 })}
                      >
                        {expand.accord6 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord6 && (
                      <Expand>
                        <AccordianBodyPara>
                          Yes, you can rent items from anywhere in the world, as
                          long as the owner is willing to ship the item to your
                          location.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 7*/}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        Can I rent items for longer than the standard rental
                        period?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord7: !expand.accord7 })}
                      >
                        {expand.accord7 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord7 && (
                      <Expand>
                        <AccordianBodyPara>
                          Yes, you can request to rent items for a longer period
                          of time by contacting the owner through our platform
                          messaging system to arrange an extended rental period.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 8*/}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        What happens if the item I rent is damaged during my
                        rental period?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord8: !expand.accord8 })}
                      >
                        {expand.accord8 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord8 && (
                      <Expand>
                        <AccordianBodyPara>
                          In the event that an item is damaged during your
                          rental period, you will be responsible for the cost of
                          repairs or replacement, as specified in our rental
                          agreement.
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>

                {/* 9 */}
                <AccordianContainer>
                  <Accordian matchesSM={matchesSM}>
                    <AccordianHeader>
                      <Text matchesSM={matchesSM}>
                        Which products can be borrowed?
                      </Text>
                      <IconButton
                        onClick={() => setExpand({ accord9: !expand.accord9 })}
                      >
                        {expand.accord9 ? (
                          <img src={accordactive} alt="img" width={50} />
                        ) : (
                          <img src={accordinactive} alt="img" width={70} />
                        )}
                      </IconButton>
                    </AccordianHeader>
                    {expand.accord9 && (
                      <Expand>
                        <AccordianBodyHeading>Electronics</AccordianBodyHeading>
                        <AccordianBodyPara>
                          Such as cameras, laptops, and smartphones
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Outdoor equipment
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Camping gear, bicycles, and sports
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Party equipment
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Tents, tables, and chairs
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>Tools</AccordianBodyHeading>
                        <AccordianBodyPara>
                          Drills, saws and lawnmowers
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Clothing and accessories
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Such as wedding dresses, suits, and jewelry
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyHeading>
                          Home appliances
                        </AccordianBodyHeading>
                        <AccordianBodyPara>
                          Like vacuums, washing machines, and kitchen
                          appliances...and much more!
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyPara>
                          It's important to note that not all items are
                          permitted to be listed on our rental marketplace. To
                          ensure the safety and legality of the transactions on
                          our platform, the online rental marketplace may
                          implement strict screening and verification processes,
                          such as ID checks, background screenings, and product
                          inspections.
                        </AccordianBodyPara>
                        <Box sx={{ marginTop: '2rem' }} />
                        <AccordianBodyPara>
                          The following items are typically prohibited, as they
                          may pose a safety risk or be illegal:
                          <Box sx={{ marginTop: '2rem' }} />
                          <li>Explosives and flammable materials</li>
                          <li>Illegal drugs and controlled substances</li>
                          <li>
                            Weapons, including firearms, ammunition, and knives
                          </li>
                          <li>Stolen or counterfeit items</li>
                          <li>Hazardous waste or chemicals</li>
                          <li>
                            Personal information and confidential documents
                          </li>
                        </AccordianBodyPara>
                      </Expand>
                    )}
                  </Accordian>
                </AccordianContainer>
              </Box>
            </MainContainer>
          </Container3>
        </Container2>
      </Container>
      {matcheslg ? null : (
        <img
          src={plant}
          alt="img"
          width={150}
          style={{ position: 'absolute', top: '136rem', right: '0' }}
        />
      )}

      {matcheslg ? null : (
        <img
          src={faqBag}
          alt="img"
          width={150}
          style={{ position: 'absolute', top: '73rem' }}
        />
      )}

      <AppFooterv2 />
    </>
  );
}

export default FAQs;
