import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import left from '../../../assets/images/left.png';
import phone from '../../../assets/images/phone.png';
import right from '../../../assets/images/right.png';
import sli1 from '../../../assets/images/sli1.png';
import sli2 from '../../../assets/images/sli2.png';
import sli3 from '../../../assets/images/sli3.png';
import sli4 from '../../../assets/images/sli4.png';
import sli5 from '../../../assets/images/sli5.png';
import sli6 from '../../../assets/images/sli6.png';

function Categories() {
  const itemData = [
    {
      id: 1,
      title: 'Sporting goods',
      description: 'Now you can play your favorite sport wherever you are',
      image: sli1
    },
    {
      id: 2,
      title: 'Electronics',
      description:
        'From tablets to blenders to TVs and fans, here is the place to go',
      image: sli2
    },
    {
      id: 3,
      title: 'Books',
      description: 'Find the classics, nonfiction and more of your own',
      image: sli3
    },
    {
      id: 4,
      title: 'Music',
      description: 'Now you can bring music to everything in life',
      image: sli4
    },
    {
      id: 5,
      title: 'Toys & Games',
      description: 'From board games to cards and video games, fun is covered',
      image: sli5
    },
    {
      id: 6,
      title: 'Office',
      description: 'Find the supplies you need for office work',
      image: sli6
    }
  ];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const RenderSlider = () =>
    itemData.map((value) => {
      return (
        <Box
          sx={{
            marginTop: '3rem',
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            flexWrap: 'wrap',
            padding: '0rem 2rem'
          }}
          key={value.id}
        >
          <Box
            sx={{
              display: 'flex',
              width: '20rem',
              textAlign: 'center',
              padding: '20px 10px 10px 10px',
              borderRadius: '9px',
              boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
              background: '#FFFFFF'
            }}
          >
            <Box>
              <img src={value.image} alt="img" style={{ width: '35%' }} />
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '36px',
                  lineHeight: '54px',
                  fontWeight: '700',
                  paddingTop: '1rem'
                }}
              >
                {value.title}
              </Typography>
              <Typography sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}>
                {value.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    });
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function SampleNextArrow(props) {
    const { className, style, onClick, slideCount, currentSlide } = props;
    const slidedPerView = 3;
    return (
      <img
        src={right}
        className={className}
        style={{
          ...style,
          display:
            currentSlide + slidedPerView == slideCount ? 'none' : 'block',
          fontSize: '1.5rem',
          color: '#235E4F',
          position: 'absolute',
          top: '22rem',
          right: '3rem',
          backgroundColor: 'white',
          border: 'solid rgb(35, 94, 79)',
          borderRadius: '50%',
          height: '3rem',
          width: '3rem'
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <img
        src={left}
        className={className}
        style={{
          ...style,
          display: currentSlide > 0 ? 'block' : 'none',
          fontSize: '1.5rem',
          color: '#235E4F',
          position: 'absolute',
          top: '22rem',
          left: 'calc(100% - 10rem)',
          backgroundColor: 'white',
          border: 'solid rgb(35, 94, 79)',
          borderRadius: '50%',
          height: '3rem',
          width: '3rem'
        }}
        onClick={onClick}
      />
    );
  }
  return (
    <>
      <Box
        sx={
          matches
            ? {
                paddingTop: '6rem',
                marginBottom: '10rem',
                background: '#F8FFF5'
              }
            : { paddingTop: '6rem', background: '#F8FFF5' }
        }
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            textAlign: 'center',
            fontWeight: '275',
            fontStyle: 'italic',
            fontSize: 'clamp(35px,5vw,60px)',
            marginBottom: '5rem'
          }}
        >
          Bring the
          <span
            style={{
              fontWeight: '500',
              fontFamily: 'Poppins',
              fontSize: 'clamp(35px,5vw,60px)',
              padding: '0px 1rem',
              fontStyle: 'normal'
            }}
          >
            categories
          </span>
        </Typography>
        <Grid container>
          {matches ? null : (
            <Grid item xs={12} md={4} sx={{ marginBottom: '5rem' }}>
              <Box>
                <img src={phone} alt="img" width={'85%'} />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Box sx={{ width: 'calc(100% - 5px)' }}>
              <Slider {...settings}>
                {itemData.map((value) => {
                  return (
                    <Box key={value.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          marginRight: '1rem',
                          textAlign: 'center',
                          height: '19rem',
                          padding: '20px 10px 39px 10px',
                          borderRadius: '9px',
                          boxShadow: '-4px 17px 94px 16px rgb(0 0 0 / 8%)',
                          background: '#86BA5E',
                          flexDirection: 'column'
                        }}
                      >
                        <img
                          src={value.image}
                          alt="img"
                          style={{ width: '7rem', margin: 'auto' }}
                        />
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '30px',
                            lineHeight: '54px',
                            fontWeight: '700',
                            paddingTop: '1rem'
                          }}
                        >
                          {value.title}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: 'Poppins', paddingTop: '6px' }}
                        >
                          {value.description}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Categories;
