import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, SectionOneContainerStyle } from './Style';
import NavBarV2 from '../../components/NavBarV2';
import AppFooterv2 from '../../components/AppFooterv2';
import youngGirl from '../../assets/images/young-girl.png';
import youngGirlCropped from '../../assets/images/young-girl-cropped.png';
import union1 from '../../assets/images/union1.png';
import union2 from '../../assets/images/union2.png';
import InputValidatorv2 from '../../components/InputValidatorv2';

function ForgotPassword() {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <NavBarV2 />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            style={{
              height: '800px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src={matchesMD ? youngGirlCropped : youngGirl}
              alt="img"
              style={{
                margin: '5rem auto 0px auto',
                width: matchesMD ? '70%' : '100%',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} spacing={8} order={{ xs: 1, md: 2 }}>
            <SectionOneContainerStyle>
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '3rem',
                  padding: '1rem',
                  boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '4rem'
                  }}
                >
                  <img src={union2} width={matchesMD ? 60 : 80} alt="img" />
                </Box>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 275,
                    fontStyle: 'italic',
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  Forgot
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  Password
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: '4rem'
                  }}
                >
                  <img src={union1} width={matchesMD ? 60 : 80} alt="img" />
                </Box>
              </Box>
              <Box>
                <Box sx={{ width: '90%', margin: 'auto' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      padding: '5rem 0rem 2rem 0rem',
                      fontSize: 24,
                      fontWeight: 300
                    }}
                  >
                    Please enter your email address below to restore your
                    password
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '90%',
                    margin: 'auto'
                  }}
                >
                  <InputValidatorv2
                    height={'10vh'}
                    forgotPasswordInput={true}
                  />
                </Box>
              </Box>
            </SectionOneContainerStyle>
          </Grid>
        </Grid>
      </Container>
      <AppFooterv2 />
    </>
  );
}

export default ForgotPassword;
