import { createTheme } from "@mui/material/styles";

const rawTheme = createTheme({
  palette: {
    primary: {
      light: "#69696a",
      main: "#808000",
      dark: "#1e1e1f",
    },
    secondary: {
      light: "black",
      main: "#ff3366",
      dark: "#e62958",
    },
  },
  typography: {
    fontFamily: "'SF Pro Display', 'Source Sans Pro', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: "none",
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,

    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      //   placeholder: grey[200],
      primary: rawTheme.palette.primary.main,
    },

    button: {
      default: rawTheme.palette.common.main,
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
    footer: {
      ...rawTheme.typography.body1,
      fontSize: 12,
    },
  },
};

export default theme;
