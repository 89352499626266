import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import upload from '../../../assets/images/upload.png';
import NavBar from '../../../components/NavBar';
import LocalCards from '../Brands/LocalCards';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AppFooter from '../../../components/AppFooter';

function ListItem() {
  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.down('md'));
  let isSmallDevice = useMediaQuery(theme.breakpoints.down('450'));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const options = [
    { value: '', text: 'Select Condition' },
    { value: '1', text: 'Poor' },
    { value: '2', text: 'Fair' },
    { value: '3', text: 'Good' },
    { value: '4', text: 'Excellent' }
  ];
  const options2 = [
    { value: '', text: 'Select Brand' },
    { value: '1', text: 'Brand 1' },
    { value: '2', text: 'Brand 2' },
    { value: '3', text: 'Brand 3' }
  ];
  const options3 = [
    { value: '', text: 'Select Category' },
    { value: '1', text: 'Category 1' },
    { value: '2', text: 'Category 2' },
    { value: '3', text: 'Category 3' }
  ];
  const [selected, setSelected] = React.useState({
    one: options[0].value,
    two: options2[0].value,
    three: options3[0].value
  });
  const [Data, setData] = React.useState({
    title: [],
    detail: []
  });

  const [image, setImage] = React.useState([]);

  const handleChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const handlefile = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    if (targetFilesObject.length > 8) return alert('Only upload 8 files');

    targetFilesObject.map((value) => {
      return selectedFIles.push(URL.createObjectURL(value));
    });
    setImage(selectedFIles);
  };

  const handleSelectChange = (e) => {
    setSelected(e.target.value);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowCircleRightIcon
        className={className}
        style={{
          ...style,
          fontSize: '1.5rem',
          color: '#1E1E1E'
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <ArrowCircleLeftIcon
        className={className}
        style={{
          ...style,
          fontSize: '1.5rem',
          color: '#1E1E1E'
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <NavBar />

      <Box
        sx={{ textAlign: 'center', marginTop: '8rem', paddingBottom: '4rem' }}
      >
        <Typography
          sx={{
            fontSize: '25px',
            lineHeight: '32px',
            marginBottom: '2rem',
            color: '#1E1E1E'
          }}
        >
          Rent Out an Item
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '3rem'
          }}
        >
          <Box sx={{ width: '30%' }}>
            <Slider {...settings}>
              {image.map((value) => {
                return (
                  <img src={value} alt="img" height={300} className="img" />
                );
              })}
            </Slider>
          </Box>
        </Box>

        {/* Custom image upload */}
        <input
          type="file"
          id="here"
          hidden
          multiple
          accept="image/*"
          onChange={(e) => handlefile(e)}
        />
        <label htmlFor="here">
          <img
            src={upload}
            style={{ width: '72%', cursor: 'pointer' }}
            alt="img"
          />
        </label>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: matches ? 'center' : 'end',
                alignItems: matches ? 'center' : 'end',
                flexDirection: 'column',
                gap: '1rem'
              }}
            >
              <Box
                sx={{
                  width: '72%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem'
                }}
              >
                <Typography
                  sx={{ fontSize: 'clamp(21px,6vw,25px)', lineHeight: '32px' }}
                >
                  Description
                </Typography>

                <input
                  type="text"
                  className="place"
                  name="title"
                  maxLength={80}
                  value={Data.title}
                  placeholder="Title (Describe what are you renting here)"
                  onChange={(e) => handleChange(e)}
                  style={{
                    height: '58px',
                    outline: 'none',
                    backgroundColor: '#E3E3E3',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '10px',
                    width: isSmallDevice ? '100%' : '90%'
                  }}
                />

                <textarea
                  cols="30"
                  name="detail"
                  value={Data.detail}
                  rows="10"
                  className="place"
                  maxLength={1000}
                  onChange={(e) => handleChange(e)}
                  placeholder="Describe your items (5+ words)"
                  style={{
                    height: '150px',
                    outline: 'none',
                    backgroundColor: '#E3E3E3',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '10px',
                    width: isSmallDevice ? '100%' : '90%'
                  }}
                ></textarea>
              </Box>
              <Box
                sx={{
                  width: '72%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  marginTop: '1rem'
                }}
              >
                <Typography
                  sx={{ fontSize: 'clamp(21px,6vw,25px)', lineHeight: '32px' }}
                >
                  Details
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  {isSmallDevice ? null : (
                    <Typography
                      sx={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        width: '7rem'
                      }}
                    >
                      Condition
                    </Typography>
                  )}

                  <select
                    value={selected.one}
                    onChange={handleSelectChange}
                    style={{
                      height: '40px',
                      background: '#E3E3E3',
                      border: 'none',
                      outline: 'none',
                      color: '#A0A0A0',
                      fontSize: 'clamp(15px, 4vw, 20px)',
                      width: isSmallDevice ? '100%' : '50%',
                      borderRadius: '4px'
                    }}
                  >
                    {options.map((options) => (
                      <option key={options.value} value={options.value}>
                        {options.text}
                      </option>
                    ))}
                  </select>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  {isSmallDevice ? null : (
                    <Typography
                      sx={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        width: '7rem'
                      }}
                    >
                      Brand
                    </Typography>
                  )}

                  <select
                    value={selected.two}
                    onChange={handleSelectChange}
                    style={{
                      height: '40px',
                      background: '#E3E3E3',
                      border: 'none',
                      outline: 'none',
                      color: '#A0A0A0',
                      fontSize: 'clamp(15px, 4vw, 20px)',
                      width: isSmallDevice ? '100%' : '50%',
                      borderRadius: '4px'
                    }}
                  >
                    {options2.map((options2) => (
                      <option key={options2.value} value={options2.value}>
                        {options2.text}
                      </option>
                    ))}
                  </select>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  {isSmallDevice ? null : (
                    <Typography
                      sx={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        width: '7rem'
                      }}
                    >
                      Category
                    </Typography>
                  )}

                  <select
                    value={selected.three}
                    onChange={handleSelectChange}
                    style={{
                      height: '40px',
                      background: '#E3E3E3',
                      border: 'none',
                      outline: 'none',
                      color: '#A0A0A0',
                      fontSize: 'clamp(15px, 4vw, 20px)',
                      width: isSmallDevice ? '100%' : '50%',
                      borderRadius: '4px'
                    }}
                  >
                    {options3.map((options3) => (
                      <option key={options3.value} value={options3.value}>
                        {options3.text}
                      </option>
                    ))}
                  </select>
                </Box>
              </Box>
              <Box
                sx={{
                  width: matches ? '100%' : '73%',
                  marginTop: isSmallDevice ? '2rem' : '5rem',
                  textAlign: matches && 'center'
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: '#1E1E1E',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    width: '191px',
                    height: '56px',
                    fontSize: '20px',
                    lineHeight: '24px',
                    boxShadow: '1px 2px 5px rgba(73, 73, 1, 0.54)',
                    borderRadius: '4px',
                    border: '1px solid #565601'
                  }}
                >
                  Save as Draft
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginTop: matches ? '3rem' : null,
              display: matches && 'flex',
              justifyContent: matches && 'center'
            }}
          >
            <Box sx={matches && { width: '72%' }}>
              <Typography
                sx={{ fontSize: 'clamp(21px,6vw,25px)', lineHeight: '24px' }}
              >
                Pricing
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: isSmallDevice ? '1rem' : '2rem',
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    marginTop: '1rem'
                  }}
                >
                  {isSmallDevice ? null : (
                    <Typography sx={{ fontSize: '20px', lineHeight: '24px' }}>
                      Price / hr
                    </Typography>
                  )}

                  <input
                    type="number"
                    placeholder="Hourly Price"
                    className="place"
                    style={{
                      width: isSmallDevice ? '225px' : '155px',
                      height: '40px',
                      border: 'none',
                      borderRadius: '4px',
                      padding: '8px 16px 8px 16px',
                      background: '#E3E3E3',
                      outline: 'none'
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    marginTop: '1rem'
                  }}
                >
                  {isSmallDevice ? null : (
                    <Typography sx={{ fontSize: '20px', lineHeight: '24px' }}>
                      Price / day
                    </Typography>
                  )}

                  <input
                    type="number"
                    placeholder="Daily Price"
                    className="place"
                    style={{
                      width: isSmallDevice ? '225px' : '155px',
                      height: '40px',
                      border: 'none',
                      borderRadius: '4px',
                      padding: '8px 16px 8px 16px',
                      background: '#E3E3E3',
                      outline: 'none'
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '25px',
                    lineHeight: '24px',
                    marginTop: '3rem'
                  }}
                >
                  Set Available Time
                </Typography>
                <Box sx={{ marginTop: '2rem' }}>
                  <Calendar />
                </Box>
                <Box
                  sx={{
                    marginTop: isSmallDevice ? '3rem' : '8rem',
                    width: matches ? '100%' : '51%',
                    textAlign: matches ? 'center' : 'end'
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      fontWeight: '400',
                      textTransform: 'capitalize',
                      width: '191px',
                      height: '56px',
                      fontSize: '20px',
                      lineHeight: '24px',
                      boxShadow: '1px 2px 5px rgba(73, 73, 1, 0.54)',
                      borderRadius: '4px'
                    }}
                  >
                    List Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Similar Items */}
      <Box
        sx={{
          width: '100%',
          height: '1vh',
          background: 'yellow',
          marginBottom: '2rem',
          marginTop: '3rem'
        }}
      />
      <Box sx={{ padding: '0rem 1rem' }}>
        <LocalCards title={'Similar Items'} listItem={true} />
      </Box>
      <AppFooter />
    </>
  );
}

export default ListItem;
