import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../redux/slices/UserSlice';
import Auth from '../auth/Auth';

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const auth = new Auth();
  const { isAuthenticated } = useSelector(userSelector);

  const pages = [
    { id: 1, component: 'Home', route: 'home' },
    { id: 2, component: 'FAQ', route: '/faq' },
    { id: 3, component: 'Blog', route: '/blog' },
    {
      component:
        auth.isAuthenticated() || isAuthenticated ? 'Profile' : 'Login',
      route: auth.isAuthenticated() || isAuthenticated ? '/profile' : '/login'
    },
    {
      component: (auth.isAuthenticated() || isAuthenticated) && 'Logout',
      action: () => {
        auth.logout();
      }
    }
  ];

  return (
    <React.Fragment>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {pages.map((page, index) => (
            <Box key={page.id}>
              <Link to={page.route} style={{ textDecoration: 'none' }}>
                <ListItemButton key={index}>
                  <ListItemIcon>
                    <ListItemText onClick={page.action}>
                      {page.component}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </Link>
            </Box>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: 'black', marginLeft: 'auto' }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
