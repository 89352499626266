import { styled } from '@mui/material/styles';
import ellipse1 from '../../assets/images/Ellipse1.png';
import ellipse2 from '../../assets/images/Ellipse2.png';
import ellipse3 from '../../assets/images/Ellipse3.png';

export const Container = styled('div')(() => ({
  backgroundImage: `url(${ellipse1})`,
  backgroundRepeat: 'no-repeat'
}));
export const Container2 = styled('div')(() => ({
  backgroundImage: `url(${ellipse2})`,
  backgroundRepeat: 'no-repeat'
}));
export const Container3 = styled('div')(() => ({
  backgroundImage: `url(${ellipse3})`,
  backgroundRepeat: 'no-repeat'
}));

export const Accessories = styled('div')(() => ({}));
