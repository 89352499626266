import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import local5 from '../../assets/images/local5.png';
import Herov2 from '../../components/Herov2';
import NavBar from '../../components/NavBar';

function Profile() {
  const [allItems, setAllItems] = React.useState();
  const [value, setValue] = React.useState(
    {
      review1: 0
    },
    {
      review2: 0
    },
    {
      review3: 0
    }
  );

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/item`)
      .then((response) => {
        setAllItems(response.data);
      })
      .catch((error) => console.log('Error =', error));
  }, []);

  return (
    <>
      <NavBar />
      <Herov2 route_profile={true} />
      <Box sx={{ width: '76%', padding: '2rem 3rem', boxSizing: 'border-box' }}>
        <Typography variant="body1">Write something about yourself</Typography>
      </Box>

      {/* Dashboard */}
      <Box sx={{ padding: '1rem 3rem' }}>
        <Box>
          <Typography
            variant="h4"
            sx={{ fontSize: '2.441rem', fontWeight: '400' }}
          >
            Dashboard
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '2rem',
            marginTop: '1rem',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              border: 'solid gray',
              padding: '0px 6px',
              borderRadius: '10px',
              textAlign: 'center'
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#808000', fontSize: '20px', fontWeight: '400' }}
            >
              Rent Out
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '25px' }}>
              $0
            </Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              0 items
            </Typography>
          </Box>
          <Box
            sx={{
              border: 'solid gray',
              padding: '0px 6px',
              borderRadius: '10px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" sx={{ color: '#808000' }}>
              Borrowed
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.7rem' }}>
              $0
            </Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              0 items
            </Typography>
          </Box>
          <Box
            sx={{
              border: 'solid gray',
              padding: '0px 6px',
              borderRadius: '10px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" sx={{ color: '#808000' }}>
              Due Item(s)
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.7rem' }}>
              $0
            </Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              0 items
            </Typography>
          </Box>
          <Box
            sx={{
              border: 'solid gray',
              padding: '0px 6px',
              borderRadius: '10px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" sx={{ color: '#808000' }}>
              Net Income
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.7rem' }}>
              $0
            </Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              0 items
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Reviews */}
      <Box
        sx={{
          padding: '3rem 3rem 0rem 3rem',
          width: '100%',
          maxWidth: '90rem'
        }}
      >
        <Box>
          <Typography variant="h4" fontWeight={500} fontSize={'2.441rem'}>
            Reviews (0)
          </Typography>
        </Box>
        {/* API of reviews will use here. */}
        {true ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '5rem',
              marginTop: '1rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Rating
                  sx={{ color: '#808000' }}
                  name="simple-controlled"
                  value={value.review1}
                  onChange={(event, newValue) => {
                    setValue({ ...value, review1: newValue });
                  }}
                />
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0' }}>
                  04/04/2022
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'start' }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 86, height: 86 }}
                  src={require('../../assets/images/client1.png')}
                />
                <Box>
                  <Typography variant="subtitle1">Sport Girl</Typography>
                  <Typography variant="body1">0 complete sales</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Rating
                  sx={{ color: '#808000' }}
                  name="simple-controlled"
                  value={value.review2}
                  onChange={(event, newValue) => {
                    setValue({ ...value, review2: newValue });
                  }}
                />
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0' }}>
                  04/04/2022
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'start' }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 86, height: 86 }}
                  src={require('../../assets/images/client1.png')}
                />
                <Box>
                  <Typography variant="subtitle1">Sport Girl</Typography>
                  <Typography variant="body1">0 complete sales</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Rating
                  sx={{ color: '#808000' }}
                  name="simple-controlled"
                  value={value.review3}
                  onChange={(event, newValue) => {
                    setValue({ ...value, review3: newValue });
                  }}
                />
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0' }}>
                  04/04/2022
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'start' }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 86, height: 86 }}
                  src={require('../../assets/images/client1.png')}
                />
                <Box>
                  <Typography variant="subtitle1">Sport Girl</Typography>
                  <Typography variant="body1">0 complete sales</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px'
              }}
            >
              You currently have no reviews.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            maxWidth: '84rem',
            padding: '2rem 1rem'
          }}
        >
          <Link to={'#'} style={{ color: 'black' }}>
            <Typography variant="subtitle">See All</Typography>
          </Link>
        </Box>
      </Box>

      {/* Current Rentals */}
      <Box
        sx={{
          padding: '0rem 3rem 0rem 3rem',
          width: '100%',
          maxWidth: '90rem'
        }}
      >
        <Box>
          <Typography variant="h4" fontWeight={500} fontSize={'2.441rem'}>
            Current Rentals
          </Typography>
        </Box>
        {/* API of rental items will use here. */}
        {true ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4rem',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '2rem'
            }}
          >
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <Box>
                <img
                  src={require('../../assets/images/local1.png')}
                  alt="img"
                  width={110}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '2rem'
                  }}
                >
                  <Box>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: '400', fontSize: '20px' }}
                      >
                        Red Dress
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ textDecoration: 'underline', color: 'gray' }}
                      >
                        Rented by mei
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
                      Due Date
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: '400' }}>
                      06/21
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '4rem' }}>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Period
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">06/17 - 06/20</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '25px', fontWeight: '400' }}
                    >
                      $20
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '4rem' }}>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Total Days
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">3 days</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Processing
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                marginRight: '9rem'
              }}
            >
              <Box>
                <img
                  src={require('../../assets/images/local1.png')}
                  alt="img"
                  width={110}
                />
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '2rem' }}>
                  <Box>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', fontSize: '20px' }}
                      >
                        Red Dress
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ textDecoration: 'underline', color: 'gray' }}
                      >
                        Rented by mei
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
                      Due Date
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: '400' }}>
                      06/21
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '4rem' }}>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Period
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">06/17 - 06/20</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '25px', fontWeight: '400' }}
                    >
                      $20
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '4rem' }}>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Total Days
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">3 days</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      Processing
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px'
              }}
            >
              You currently are not renting.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            maxWidth: '90rem',
            padding: '2rem 1rem'
          }}
        >
          <Link to={'#'} style={{ color: 'black' }}>
            <Typography variant="subtitle">See All</Typography>
          </Link>
        </Box>
      </Box>

      {/* Your Items */}
      <Box sx={{ padding: '3rem 3rem 0rem 3rem' }}>
        <Box>
          <Typography variant="h4" fontWeight={500} fontSize={'2.441rem'}>
            Your Items
          </Typography>
        </Box>
        {allItems?.length > 0 ? (
          <Box sx={{ padding: '2rem 0rem 0rem 0rem' }}>
            <ImageList
              gap={12}
              sx={{
                mb: 8,
                gridTemplateColumns:
                  'repeat(auto-fill, minmax(158px, 1fr))!important'
              }}
            >
              {allItems?.map((item) => (
                <ImageListItem key={item.id} sx={{ height: '100% !important' }}>
                  <img
                    src={`${local5}?w=248&fit=crop&auto=format`}
                    srcSet={`${local5}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={
                      <Box
                        flexDirection={'row'}
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Typography
                          variant={'subtitle2'}
                          sx={{ fontSize: '20px', fontWeight: '400' }}
                        >
                          {item.title}
                        </Typography>

                        <Typography variant={'body2'} sx={{ fontSize: '25px' }}>
                          ${parseInt(item.dailyRentalPrice).toString()}
                        </Typography>
                      </Box>
                    }
                    subtitle={
                      <Typography variant={'body3'} sx={{ color: '#A0A0A0' }}>
                        {'item.paymentType'}
                      </Typography>
                    }
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        ) : (
          <Box>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px'
              }}
            >
              You have not listed any items.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            maxWidth: '86rem',
            padding: '0rem 1rem 2rem 1rem'
          }}
        >
          <Link to={'#'} style={{ color: 'black' }}>
            <Typography variant="subtitle">See All</Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default Profile;
