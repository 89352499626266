import { Box, Typography } from '@mui/material';
import React from 'react';
import m1 from '../../../assets/images/m1.png';
import m2 from '../../../assets/images/m2.png';
import m3 from '../../../assets/images/m3.png';

function ModernsApp() {
  return (
    <>
      <Box sx={{ background: '#F8FFF5' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '6rem',
            textAlign: 'center',
            fontWeight: '275',
            fontStyle: 'italic',
            fontSize: 'clamp(35px,5vw,60px)',
            marginBottom: '4rem'
          }}
        >
          A modern
          <span
            style={{
              fontWeight: '500',
              fontFamily: 'Poppins',
              fontSize: 'clamp(35px,5vw,60px)',
              padding: '0px 1rem',
              fontStyle: 'normal'
            }}
          >
            app
          </span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              width: '23rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3rem'
            }}
          >
            <img
              src={m1}
              alt="img"
              width={'287rem'}
              style={{ borderRadius: '20px' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingTop: '2rem',
                width: '18rem'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '1.6rem',
                  fontWeight: '600',
                  color: '#191A15'
                }}
              >
                Fluid navigation
              </Typography>
              <Typography
                sx={{
                  color: '#656565',
                  fontSize: '1.1rem',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  lineHeight: '27px'
                }}
              >
                The app feels responsive and smooth providing a great user
                experience
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '23rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3rem'
            }}
          >
            <img
              src={m2}
              alt="img"
              width={'287rem'}
              style={{ borderRadius: '20px' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingTop: '2rem',
                width: '18rem'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '1.6rem',
                  fontWeight: '600',
                  color: '#191A15'
                }}
              >
                Payment options
              </Typography>
              <Typography
                sx={{
                  color: '#656565',
                  fontSize: '1.1rem',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  lineHeight: '27px'
                }}
              >
                Many options available including credit/debit cards, cash and
                PayPal
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '23rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3rem'
            }}
          >
            <img
              src={m3}
              alt="img"
              width={'287rem'}
              style={{ borderRadius: '20px' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingTop: '2rem',
                width: '18rem'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '1.6rem',
                  fontWeight: '600',
                  color: '#191A15'
                }}
              >
                Product ratings
              </Typography>
              <Typography
                sx={{
                  color: '#656565',
                  fontSize: '1.1rem',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  lineHeight: '27px'
                }}
              >
                Trustworthy opinions of true clients!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ModernsApp;
