import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Auth from '../../auth/Auth';
import AppFooter from '../../components/AppFooterv2';
import ImageSlider from '../../components/ImageSlider';
import MeetSeller from '../../components/MeetSeller';
import NavBar from '../../components/NavBar';
import SellerReviews from '../../components/SellerReviews';
import VerticalCarousal from '../../components/VerticalCarousal';
import { userSelector } from '../../redux/slices/UserSlice';
import LocalCards from '../MarketingPage1/Brands/LocalCards';
import {
  ApplePay,
  GPay,
  PayPal,
  Visa,
  MasterCard
} from '../../assets/images/AmazonS3/index';

const ItemDetail = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const auth = new Auth();
  const { isAuthenticated } = useSelector(userSelector);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (!isAuthenticated || !auth.isAuthenticated()) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavBar />

      <Grid container spacing={1} sx={{ marginTop: '6rem' }}>
        <Grid item xs={12} md={7}>
          <Grid container>
            {matches ? null : (
              <Grid item xs={4} style={{ position: 'relative' }}>
                {matches ? null : <VerticalCarousal />}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={8}
              sx={
                matches
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }
                  : null
              }
            >
              <ImageSlider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={matches ? { textAlign: 'center' } : null}>
            <Typography
              variant={matches ? 'subtitle2' : 'h4'}
              gutterBottom
              sx={{ fontSize: '39.06px', fontWeight: '400' }}
            >
              Farmer Hat with Red Ribbon
              <IconButton
                size="medium"
                sx={{ border: 'solid gray', marginLeft: '1rem' }}
              >
                <FavoriteBorderIcon fontSize="medium" sx={{ color: 'black' }} />
              </IconButton>
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'gray', fontSize: '16px' }}
            >
              Last Update 1 day ago
            </Typography>
            <Box
              sx={
                matches
                  ? {
                      display: 'flex',
                      marginTop: '1rem',
                      justifyContent: 'center'
                    }
                  : { display: 'flex', marginTop: '1rem' }
              }
            >
              <Button
                variant="contained"
                sx={{ fontSize: '19.25px', fontWeight: '400' }}
              >
                $20/day
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: 'solid #808000',
                  marginLeft: '1rem',
                  fontSize: '19.25px',
                  fontWeight: '400',
                  '&:hover': {
                    backgroundColor: '#808000',
                    color: 'white'
                  }
                }}
              >
                $5/hour
              </Button>
            </Box>
            <Box
              sx={
                matches
                  ? {
                      display: 'flex',
                      marginTop: '2rem',
                      justifyContent: 'center'
                    }
                  : { display: 'flex', marginTop: '2rem' }
              }
            >
              {matches ? null : (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '19.25px',
                    fontWeight: '400',
                    textTransform: 'capitalize'
                  }}
                >
                  Message
                </Button>
              )}
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: 'solid #808000',
                    marginLeft: '1rem',
                    fontSize: '19.25px',
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    '&:hover': {
                      backgroundColor: '#808000',
                      color: 'white'
                    }
                  }}
                  onClick={handleClickOpen}
                >
                  Rent Today
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You are a guest please login to continue!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button sx={{ color: 'black' }} onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                      <Link
                        to={'/login'}
                        style={{ textDecoration: 'none', color: '#808000' }}
                      >
                        Login in
                      </Link>
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            </Box>
            {matches ? (
              <Box
                sx={{
                  width: '100%',
                  height: '1vh',
                  background: 'yellow',
                  marginBottom: '2rem',
                  marginTop: '1rem'
                }}
              />
            ) : null}

            {/* payment icons */}

            {matches ? null : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '1rem',
                    width: '70%',
                    flexWrap: 'wrap'
                  }}
                >
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={Visa} alt="visa.png" />
                  </Button>
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={MasterCard} alt="mastercard.png" />
                  </Button>
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={ApplePay} alt="applepay.png" />
                  </Button>
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={GPay} alt="gpay.png" />
                  </Button>
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={PayPal} alt="paypal.png" />
                  </Button>
                  <Button
                    sx={{
                      boxShadow: '0px 2px 5px 0px grey',
                      margin: '6px 6px',
                      height: '6vh'
                    }}
                  >
                    <img width={50} src={PayPal} alt="paypal.png" />
                  </Button>
                </Box>

                <Typography
                  variant="body2"
                  sx={{ color: 'gray', marginTop: '6px', fontSize: '16px' }}
                >
                  Borrowed keeps your payment information secure. Borrowed{' '}
                  <br></br>
                  seller never receive your credit card information
                </Typography>
              </>
            )}

            {/* overview */}
            <Typography variant="h6" sx={{ marginTop: '1rem' }}>
              Overview
            </Typography>
            <Box
              sx={
                matches
                  ? {
                      display: 'flex',
                      gap: '2rem',
                      justifyContent: 'center'
                    }
                  : { display: 'flex', gap: '2rem' }
              }
            >
              <Box>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', color: 'gray' }}
                    >
                      Condition
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', textAlign: 'start' }}
                    >
                      Good
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', color: 'gray' }}
                    >
                      Brand
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', textAlign: 'start' }}
                    >
                      Tennis
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', color: 'gray' }}
                    >
                      Category
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', textAlign: 'start' }}
                    >
                      Sports, Outdoor, Tennis
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {matches ? (
              <Box
                sx={{
                  width: '100%',
                  height: '1vh',
                  background: 'yellow',
                  marginBottom: '2rem',
                  marginTop: '1rem'
                }}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        {matches ? (
          <Grid item xs={12} md={5}>
            {/* Description */}

            <Typography
              variant="h6"
              sx={{ marginTop: '1rem', textAlign: 'center' }}
            >
              Description
            </Typography>
            <Box
              sx={
                matches
                  ? { width: '100%', textAlign: 'center' }
                  : { width: '70%' }
              }
            >
              <Typography
                variant="body1"
                sx={{
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  padding: '0rem 2rem'
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </Typography>

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}

              {/* Set Time */}
              <Typography
                variant="h6"
                sx={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                Set Time
              </Typography>
              <Box
                sx={
                  matches ? { display: 'flex', justifyContent: 'center' } : null
                }
              >
                <Calendar />
              </Box>

              <Box
                sx={
                  matches
                    ? {
                        display: 'flex',
                        alignItems: 'flex-end',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginBottom: '2rem'
                      }
                    : { display: 'flex', alignItems: 'flex-end', gap: '2rem' }
                }
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: '1rem',
                      color: 'gray'
                    }}
                  >
                    Total Rent Days: 3 days x $20
                  </Typography>
                  <Typography
                    variant="button"
                    sx={{
                      marginTop: '1rem'
                    }}
                  >
                    Total Rent Fee: $60
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                >
                  Borrow Now
                </Button>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={7}>
            {matches ? (
              <Box
                sx={{
                  width: '100%',
                  height: '1vh',
                  background: 'yellow',
                  marginBottom: '2rem',
                  marginTop: '1rem'
                }}
              />
            ) : null}

            <Box sx={{ marginBottom: '2rem' }}>
              <Box sx={{ marginBottom: '2rem' }}>
                <MeetSeller />
              </Box>
              {matches ? null : <SellerReviews />}

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}

              {/* payment */}

              {matches ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '3rem'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={matches ? { textAlign: 'center' } : null}
                  >
                    Payment
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '1rem',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={Visa} alt="visa.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={MasterCard} alt="mastercard.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={ApplePay} alt="applepay.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={GPay} alt="gpay.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={PayPal} alt="paypal.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={PayPal} alt="paypal.png" />
                    </Button>
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{
                      color: 'gray',
                      marginTop: '6px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Borrowed keeps your payment information secure. Borrowed{' '}
                    <br></br>
                    seller never receive your credit card information
                  </Typography>
                </Box>
              ) : null}

              {/* divider */}

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}
            </Box>
          </Grid>
        )}

        {matches ? (
          <Grid item xs={12} md={7}>
            {matches ? (
              <Box
                sx={{
                  width: '100%',
                  height: '1vh',
                  background: 'yellow',
                  marginBottom: '2rem',
                  marginTop: '1rem'
                }}
              />
            ) : null}

            <Box sx={{ marginBottom: '2rem' }}>
              <Box sx={{ marginBottom: '2rem' }}>
                <MeetSeller />
              </Box>
              {matches ? null : <SellerReviews />}

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}

              {/* payment */}

              {matches ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '3rem'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={matches ? { textAlign: 'center' } : null}
                  >
                    Payment
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '1rem',
                      // width: "70%",
                      flexWrap: 'wrap'
                    }}
                  >
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={Visa} alt="visa.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={MasterCard} alt="mastercard.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={ApplePay} alt="applepay.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={GPay} alt="gpay.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={PayPal} alt="paypal.png" />
                    </Button>
                    <Button
                      sx={{
                        boxShadow: '0px 2px 5px 0px grey',
                        margin: '6px 6px',
                        height: '6vh'
                      }}
                    >
                      <img width={50} src={PayPal} alt="paypal.png" />
                    </Button>
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{
                      color: 'gray',
                      marginTop: '6px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Borrowed keeps your payment information secure. Borrowed{' '}
                    <br></br>
                    seller never receive your credit card information
                  </Typography>
                </Box>
              ) : null}

              {/* divider */}

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={5}>
            {/* Description */}

            <Typography variant="h6" sx={{ marginTop: '1rem' }}>
              Description
            </Typography>
            <Box sx={matches ? { width: '100%' } : { width: '70%' }}>
              <Typography
                variant="body1"
                sx={{ marginTop: '1rem', marginBottom: '2rem' }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </Typography>

              {matches ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '1vh',
                    background: 'yellow',
                    marginBottom: '2rem',
                    marginTop: '1rem'
                  }}
                />
              ) : null}

              {/* Set Time */}
              <Typography
                variant="h6"
                sx={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                Set Time
              </Typography>
              <Box
                sx={
                  matches ? { display: 'flex', justifyContent: 'center' } : null
                }
              >
                <Calendar />
              </Box>

              <Box
                sx={
                  matches
                    ? {
                        display: 'flex',
                        alignItems: 'flex-end',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginBottom: '2rem'
                      }
                    : { display: 'flex', alignItems: 'flex-end', gap: '2rem' }
                }
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: '1rem', color: 'gray', fontSize: '16px' }}
                  >
                    Total Rent Days: 3 days x $20
                  </Typography>
                  <Typography
                    variant="button"
                    sx={{
                      marginTop: '1rem',
                      fontSize: '20px',
                      textTransform: 'capitalize'
                    }}
                  >
                    Total Rent Fee: $60
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '20px',
                    fontWeight: '400',
                    textTransform: 'capitalize'
                  }}
                >
                  Borrow Now
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      {matches ? null : (
        <Box
          sx={{
            width: '100%',
            height: '1vh',
            background: 'yellow',
            marginBottom: '2rem',
            marginTop: '3rem'
          }}
        />
      )}
      <Box sx={{ paddingLeft: '10px' }}>
        <LocalCards title={'Similar Items'} />
      </Box>
      <AppFooter />
    </>
  );
};

export default ItemDetail;
