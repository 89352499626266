export const logo =
  'https://borrowed-images.s3.amazonaws.com/images/borrowedLogoTitle.png';
export const loginCover =
  'https://borrowed-images.s3.amazonaws.com/images/loginCover.png';
export const signupCover =
  'https://borrowed-images.s3.amazonaws.com/images/signupCover.png';

export const GmailIcon =
  'https://borrowed-images.s3.amazonaws.com/images/Gmail.png';

export const FacebookIcon =
  'https://borrowed-images.s3.amazonaws.com/images/FB.png';

export const AppleIcon =
  'https://borrowed-images.s3.amazonaws.com/images/Apple.png';

export const ApplePay =
  'https://borrowed-images.s3.amazonaws.com/images/applepay.png';

export const GPay = 'https://borrowed-images.s3.amazonaws.com/images/gpay.png';

export const PayPal =
  'https://borrowed-images.s3.amazonaws.com/images/paypal.png';

export const Visa = 'https://borrowed-images.s3.amazonaws.com/images/visa.png';

export const MasterCard =
  'https://borrowed-images.s3.amazonaws.com/images/mastercard.png';

export const BackgroundImgHero =
  'https://borrowed-images.s3.amazonaws.com/images/background.png';

export const firstImage =
  'https://borrowed-images.s3.amazonaws.com/images/01.png';
export const secondImage =
  'https://borrowed-images.s3.amazonaws.com/images/02.png';
export const thirdImage =
  'https://borrowed-images.s3.amazonaws.com/images/03.png';
export const fourthImage =
  'https://borrowed-images.s3.amazonaws.com/images/04.png';
export const aboutus1 =
  'https://borrowed-images.s3.amazonaws.com/images/aboutus1.png';
export const aboutus2 =
  'https://borrowed-images.s3.amazonaws.com/images/aboutus2.png';
export const aboutus3 =
  'https://borrowed-images.s3.amazonaws.com/images/aboutus3.png';
export const aboutus4 =
  'https://borrowed-images.s3.amazonaws.com/images/aboutus4.png';
export const faqBackground =
  'https://borrowed-images.s3.amazonaws.com/images/faqBackground1.png';
export const blog5 = 'https://borrowed-images.s3.amazonaws.com/blog/blog5.png';
export const faqs = 'https://borrowed-images.s3.amazonaws.com/faq/faqs.png';
export const faqBag = 'https://borrowed-images.s3.amazonaws.com/faq/faqBag.png';
export const plant = 'https://borrowed-images.s3.amazonaws.com/faq/plant.png';
