import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '200px',
    height: '60px',
    background: '#235E4F',
    borderRadius: '15px',
    cursor: 'pointer'
  },
  label: {
    color: '#FFFFFF',
    fontFamily: 'Poppins'
  }
};

const IconButton = (props) => {
  const { classes } = props;

  return (
    <>
      {classes && (
        <button className={classes.btn}>
          <img src={props.icon} alt="img" />
          <span className={classes.label}>{props.label}</span>
        </button>
      )}
    </>
  );
};
export default withStyles(styles)(IconButton);
