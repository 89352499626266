import React from 'react';
import NavBarV2 from '../../components/NavBarV2';
import Herov3 from './Herov3/Herov3';
import { Container, Container2, Container3 } from './Style';
import Categories from './Categories/Categories';
import ModernsApp from './ModernApp/ModernsApp';
import AllTheBenefits from './AllTheBenefits/AllTheBenefits';
import HowBorrowedWorks from './HowBorrowedWorks/HowBorrowedWorks';
import StayTuned from './StayTuned/StayTuned';
import AppFooterv2 from '../../components/AppFooterv2';

export default function index() {
  return (
    <>
      <Container>
        <Container2>
          <Container3>
            <NavBarV2 />
            <Herov3 />
            <Categories />
            <ModernsApp />
            <AllTheBenefits />
            <HowBorrowedWorks />
            <StayTuned />
          </Container3>
        </Container2>
      </Container>
      <AppFooterv2 />
    </>
  );
}
