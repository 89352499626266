import { Navigate, Routes as ReactRoute, Route } from 'react-router-dom';
import Auth from '../auth/Auth';
import Callback from '../views/Authorization/CallBack';
import Login from '../views/Authorization/Login';
import Signup from '../views/Authorization/Signup';
import ItemDetail from '../views/ItemsPage/ItemDetail';
// import MarketingPage from '../views/MarketingPage1';
import EditProfile from '../views/MarketingPage1/EditProfile/EditProfile';
import ListItem from '../views/MarketingPage1/ListItem/ListItem';
import Contactus from '../views/SubPages/Contactus/Contactus';
import PrivacyPolicy from '../views/SubPages/PrivacyPolicy/PrivacyPolicy';
import ComingSoon from '../views/comingSoon/ComingSoon';
import BlogArticle from '../views/SubPages/BlogArticle/BlogArticle';
import Aboutus from '../views/SubPages/About/Aboutus';
import Blog from '../views/SubPages/Blog/Blog';
import ForgotPassword from '../views/forgotPassword/ForgotPassword';
import FAQs from '../views/SubPages/FAQ/FAQs';
import LandingPage from '../views/LandingPage/index';
import LoggedIn from '../views/Home/index';
import Profile from '../views/ProfilePage/Profile';
import NotFound from '../views/notFoundPage/NotFound';
import { useSelector } from 'react-redux';
import { userSelector } from '../redux/slices/UserSlice';

function Routes() {
  const auth = new Auth();
  const { isAuthenticated } = useSelector(userSelector);

  return (
    <ReactRoute>
      <Route path="/" element={<LandingPage />} />
      {/*<Route path="/old" element={<MarketingPage />} />*/}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/blog/:id" element={<BlogArticle />} />
      {/* Need to make this page */}
      <Route path="/terms" element={<PrivacyPolicy />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/contact-us" element={<Contactus />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/item-detail" element={<ItemDetail />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="/faq" element={<FAQs />} />
      {/* Need to rename this page */}
      {auth.isAuthenticated() ? (
        <Route path="/list-an-item" element={<ListItem />} />
      ) : null}
      {auth.isAuthenticated() || isAuthenticated ? (
        <Route path="/edit-profile" element={<EditProfile />} />
      ) : null}
      {auth.isAuthenticated() || isAuthenticated ? (
        <Route path="/profile" element={<Profile />} />
      ) : null}
      {auth.isAuthenticated() || isAuthenticated ? (
        <Route path="/home" element={<LoggedIn />} />
      ) : null}
      <Route path="*" element={<Navigate to={'/not-found'} />} />
    </ReactRoute>
  );
}

export default Routes;
