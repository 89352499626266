import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, SectionOneContainerStyle } from './Style';
import NavBarV2 from '../../components/NavBarV2';
import AppFooterv2 from '../../components/AppFooterv2';
import man from '../../assets/images/indoor-shoot-man-cropped.png';
import backIcon from '../../assets/images/back.png';
import union1 from '../../assets/images/union1.png';
import union2 from '../../assets/images/union2.png';
import IconButton from '../../components/IconButton';
import { NavLink } from 'react-router-dom';

function NotFound() {
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <NavBarV2 />
      <Container>
        <Grid container>
          <Grid
            item
            sx={{
              display: { xs: 'none', sm: 'block', md: 'none', lg: 'block' }
            }}
            sm={1}
            lg={1}
          ></Grid>
          <Grid item xs={12} md={6} lg={5} spacing={8}>
            <SectionOneContainerStyle>
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '3rem',
                  padding: '1rem',
                  boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '4rem'
                  }}
                >
                  <img src={union2} width={matchesMD ? 60 : 80} alt="img" />
                </Box>
                <Typography
                  sx={{
                    fontSize: matchesSM ? '80px' : '100px',
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                    lineHeight: '5rem',
                    letterSpacing: '-0.05em'
                  }}
                >
                  404
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    padding: '0rem',
                    fontSize: 36,
                    fontWeight: 400,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  Page not found
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: '4rem'
                  }}
                >
                  <img src={union1} width={matchesMD ? 60 : 80} alt="img" />
                </Box>
              </Box>
              <Box>
                <Box sx={{ width: '90%', margin: 'auto' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      padding: '2rem 0rem 2rem 0rem',
                      fontSize: 24,
                      fontWeight: 300,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    The page you are looking for does not exist
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <NavLink to={'/'}>
                    <IconButton label="Go back to home" icon={backIcon} />
                  </NavLink>
                </Box>
              </Box>
            </SectionOneContainerStyle>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{
              height: '800px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src={man}
              alt="img"
              style={{
                margin: '5rem auto 0px auto',
                width: matchesMD ? '70%' : '100%',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <AppFooterv2 />
    </>
  );
}

export default NotFound;
