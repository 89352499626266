import { Box, Button, List, ListItem, MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import NavBarV2 from '../../../components/NavBarV2';
import AppFooterv2 from '../../../components/AppFooterv2';
import {
  Label,
  TextFieldContainer,
  FormContainer,
  BannerContainer
} from './Style';

function Contactus() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <NavBarV2 />
      <Box sx={{ marginTop: '4rem' }}>
        {/* Banner */}
        <BannerContainer>
          <Typography
            variant="h4"
            sx={{ color: 'white', fontFamily: 'Poppins' }}
          >
            Contact Us
          </Typography>
          <Typography
            sx={{
              color: 'white',
              textTransform: 'capitalize',
              fontSize: '16px',
              fontFamily: 'Poppins'
            }}
          >
            we would love to hear from you
          </Typography>
        </BannerContainer>

        {/* Form */}
        <FormContainer>
          <TextFieldContainer>
            <Label>Your Name : </Label>
            <TextField
              variant="outlined"
              placeholder="Example : John Smith"
              fullWidth={true}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Label>E-mail address: </Label>
            <TextField
              type="email"
              variant="outlined"
              placeholder="Example : john@smith.com"
              fullWidth={true}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Label>Subject :</Label>
            <TextField variant="outlined" fullWidth={true} />
          </TextFieldContainer>
          <TextFieldContainer>
            <Label>Enquire About :</Label>
            <Select variant="outlined" fullWidth={true}>
              <MenuItem value="General Inquiry"><em>General Inquiry</em></MenuItem>
              <MenuItem value="Account Assistance"><em>Account Assistance</em></MenuItem>
              <MenuItem value="Buying & Selling"><em>Buying & Selling</em></MenuItem>
              <MenuItem value="Payment Issues"><em>Payment Issues</em></MenuItem>
              <MenuItem value="Technical Support"><em>Technical Support</em></MenuItem>
              <MenuItem value="Report a Problem"><em>Report a Problem</em></MenuItem>
              <MenuItem value="Feedback & Suggestion"><em>Feedback & Suggestion</em></MenuItem>
            </Select>
          </TextFieldContainer>
          <TextFieldContainer>
            <Label>Details :</Label>
            <TextField
              multiline
              rows={4}
              placeholder="Please write your question or a description of a problem you are trying to solve here"
              fullWidth={true}
            />
          </TextFieldContainer>
          <Button
            sx={{
              marginTop: '2rem',
              background: '#86BB5E',
              borderRadius: '10px',
              padding: '6px 2rem',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              color: 'white',
              border: 'none',
              fontSize: '1.1rem',
              cursor: 'pointer',
              '&:hover': {
                background: '#86BB5E'
              }
            }}
          >
            Submit
          </Button>
        </FormContainer>
      </Box>
      <AppFooterv2 />
    </>
  );
}

export default Contactus;
