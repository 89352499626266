import { styled } from '@mui/material/styles';

export const BannerContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  backgroundColor: '#86BB5E',
  height: '30vh',
  padding: '0rem 1rem',
  marginTop: '4rem'
}));
