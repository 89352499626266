import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import ball from '../../../assets/images/ball.png';
import girl from '../../../assets/images/girl.png';
import handbag from '../../../assets/images/handbag.png';
import s1 from '../../../assets/images/S1.png';
import s2 from '../../../assets/images/S2.png';
import InputValidatorv2 from '../../../components/InputValidatorv2';
import { Container, SectionOneContainerStyle } from './Style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Herov3() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Container>
        <Grid container>
          {/* section 1 */}
          <Grid item xs={12} md={6}>
            <SectionOneContainerStyle>
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '3rem',
                  padding: '1rem',
                  boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '4rem'
                  }}
                >
                  <img src={s1} width={100} alt="img" />
                </Box>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 275,
                    fontStyle: 'italic',
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  A new platform
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  where you can
                </Typography>
                <Typography
                  sx={{
                    fontSize: 'clamp(40px,5vw,64px)',
                    fontWeight: 275,
                    fontStyle: 'italic',
                    fontFamily: 'Poppins',
                    lineHeight: '5rem'
                  }}
                >
                  borrow anything!
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: '4rem'
                  }}
                >
                  <img src={s2} width={100} alt="img" />
                </Box>
              </Box>
              <Box>
                <Box sx={{ width: '80%', marginLeft: '5rem' }}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        padding: '5rem 0rem 2rem 0rem',
                        fontSize: 24
                      }}
                    >
                      Enter your email for updates!
                    </Typography>
                  </Box>
                  <Box>
                    <InputValidatorv2 height={'7vh'} />
                  </Box>
                </Box>
              </Box>
            </SectionOneContainerStyle>
          </Grid>
          {/* section 2 */}
          {matches ? null : (
            <Grid item xs={12} md={6}>
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingRight: '9rem'
                }}
              >
                <Box
                  sx={{
                    width: '15rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '10px',
                    gap: '2rem'
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: '53%',
                      background: '#FFFFFF',
                      borderRadius: '10px',
                      border: 'none',
                      padding: '10px',
                      fontFamily: 'Poppins'
                    }}
                  ></input>
                  <button
                    style={{
                      background: '#86BB5E',
                      borderRadius: '40px',
                      padding: '5px 10px',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize',
                      color: 'white',
                      border: 'none',
                      fontSize: '1.1rem',
                      cursor: 'pointer'
                    }}
                  >
                    search
                  </button>
                </Box>
              </Box> */}
              <img
                src={ball}
                width={'17%'}
                alt="img"
                style={{ position: 'relative', top: '13rem', left: '7rem' }}
              />
              <img
                src={handbag}
                alt="img"
                style={{ float: 'right', marginTop: '-3rem' }}
              />
              <img
                src={girl}
                width={'100%'}
                alt="img"
                style={{ marginTop: '-1rem' }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default Herov3;
