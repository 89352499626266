import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import local1 from '../assets/images/local1.png';
import local2 from '../assets/images/local2.png';
import local3 from '../assets/images/local3.png';
import local4 from '../assets/images/local4.png';
import local5 from '../assets/images/local5.png';
import local6 from '../assets/images/local6.png';

const styles = {};

function ClientCarousal(props) {
  const responsive = {
    largeLaptop: {
      breakpoint: { max: 4000, min: 1150 },
      items: 4
    },
    smallLaptop: {
      breakpoint: { max: 1150, min: 866 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 866, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '10rem',
        transform: 'rotate(90deg)',
        width: '24rem',
        minWidth: '24rem',
        maxWidth: '34rem',
        right: '-7rem'
      }}
    >
      <Carousel responsive={responsive} swipeable={true}>
        {itemData.map((value) => {
          return (
            <Box key={value.id} sx={{ transform: 'rotate(-90deg)' }}>
              <img
                src={`${value.img}`}
                alt="img"
                width={'100%'}
                height={'70vh'}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
}

export default withStyles(styles)(ClientCarousal);

const itemData = [
  {
    id: 1,
    img: local1
  },
  {
    id: 2,
    img: local2
  },
  {
    id: 3,
    img: local3
  },
  {
    id: 4,
    img: local4
  },
  {
    id: 5,
    img: local5
  },
  {
    id: 6,
    img: local6
  }
];
