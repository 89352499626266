import * as React from 'react';

import { Box, Container, Typography } from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Link } from 'react-router-dom';

import local1 from '../../../assets/images/local1.png';
import local2 from '../../../assets/images/local2.png';
import local3 from '../../../assets/images/local3.png';
import local4 from '../../../assets/images/local4.png';
import local5 from '../../../assets/images/local5.png';
import local6 from '../../../assets/images/local6.png';
import local7 from '../../../assets/images/local7.png';
import local8 from '../../../assets/images/local8.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function LocalCards({ title, listItem }) {
  let matches;
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.down('md'));
  const [ViewMore, setViewMore] = React.useState(false);
  const [DisplayViewMore, setDisplayViewMore] = React.useState([]);
  const limit = 7;
  const array = itemData.splice(limit, itemData.length);
  if (array.length) {
    setDisplayViewMore(array);
  }

  return (
    <Container style={{ padding: 0, marginTop: matches ? null : '2rem' }}>
      <Typography
        variant={'h6'}
        sx={{
          color: '#1E1E1E',
          paddingBottom: '2rem',
          fontWeight: '400',
          fontSize: 'clamp(1.3rem,2.7125vw,2.441rem)',
          display: listItem && 'flex',
          gap: listItem && '1rem',
          alignItems: listItem && 'center'
        }}
      >
        {title}
        {listItem && (
          <Typography
            sx={{
              textDecoration: 'underline',
              fontSize: '16px',
              color: '#1E1E1E',
              cursor: 'pointer'
            }}
            onClick={() => setViewMore(true)}
          >
            See More
          </Typography>
        )}
      </Typography>

      <ImageList
        gap={12}
        sx={{
          mb: 8,
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))!important'
        }}
      >
        {itemData.map((item, index) => (
          <Link
            to="/item-detail"
            style={{ textDecoration: 'none', color: 'black' }}
            key={index}
          >
            <ImageListItem sx={{ height: '100% !important' }}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={
                  <Box
                    flexDirection={'row'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'subtitle2'}
                      sx={{ fontWeight: '400' }}
                    >
                      {item.title}
                    </Typography>

                    <Typography variant={'body2'} sx={{ fontSize: '20px' }}>
                      ${item.price}
                    </Typography>
                  </Box>
                }
                subtitle={
                  <Typography variant={'body3'} sx={{ color: '#A0A0A0' }}>
                    {item.paymentType}
                  </Typography>
                }
                position="below"
              />
            </ImageListItem>
          </Link>
        ))}
      </ImageList>

      <ImageList
        gap={12}
        sx={{
          mb: 8,
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))!important'
        }}
      >
        {ViewMore &&
          DisplayViewMore.map((item, index) => (
            <Link
              to="/item-detail"
              style={{ textDecoration: 'none', color: 'black' }}
              key={index}
            >
              <ImageListItem sx={{ height: '100% !important' }}>
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={
                    <Box
                      flexDirection={'row'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        variant={'subtitle2'}
                        sx={{ fontWeight: '400' }}
                      >
                        {item.title}
                      </Typography>

                      <Typography variant={'body2'} sx={{ fontSize: '20px' }}>
                        ${item.price}
                      </Typography>
                    </Box>
                  }
                  subtitle={
                    <Typography variant={'body3'} sx={{ color: '#A0A0A0' }}>
                      {item.paymentType}
                    </Typography>
                  }
                  position="below"
                />
              </ImageListItem>
            </Link>
          ))}
      </ImageList>

      {listItem ? null : (
        <Box sx={{ textAlign: 'end' }}>
          <Typography
            sx={{
              textDecoration: 'underline',
              fontSize: '16px',
              color: '#1E1E1E',
              cursor: 'pointer'
            }}
            onClick={() => setViewMore(true)}
          >
            See More
          </Typography>
        </Box>
      )}
    </Container>
  );
}

const itemData = [
  {
    img: local1,
    title: 'Red Dress',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local2,
    title: 'Black Shirt',
    paymentType: 'Payment per week',
    price: 20
  },
  {
    img: local3,
    title: 'Shoes',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local4,
    title: 'Red Dress',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local5,
    title: 'Black Shirt',
    paymentType: 'Payment per week',
    price: 20
  },
  {
    img: local6,
    title: 'Shoes',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local7,
    title: 'Red Dress',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local8,
    title: 'Black Shirt',
    paymentType: 'Payment per week',
    price: 20
  },
  {
    img: local6,
    title: 'Shoes',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local7,
    title: 'Red Dress',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local8,
    title: 'Black Shirt',
    paymentType: 'Payment per week',
    price: 20
  },
  {
    img: local6,
    title: 'Shoes',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local7,
    title: 'Red Dress',
    paymentType: 'Payment per day',
    price: 20
  },
  {
    img: local8,
    title: 'Black Shirt',
    paymentType: 'Payment per week',
    price: 20
  }
];
