import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import logo from '../assets/images/borrowLogo.png';
import insta from '../assets/images/insta.png';
import linkdin from '../assets/images/linkdin.png';
import twitter from '../assets/images/twitter.png';
import FooterLinksv2 from './FooterLinksv2';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = (theme) => ({
  footerText: {
    color: 'white',

    ...theme.typography.footer
  },
  footerTitle: {
    color: 'white',
    marginBottom: theme.spacing(1)
  },

  footerContent1: {
    marginBottom: theme.spacing(5)
  },

  logoFooterStyle: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginTop: 30,

      width: '100%',
      paddingBottom: 20
    },

    display: 'flex',
    justifyContent: 'flex-start',

    [theme.breakpoints.up('xs')]: {
      paddingLeft: 20,
      paddingTop: 20,
      marginBottom: 10
    },

    rowContainer: {
      marginBottom: theme.spacing(4)
    }
  }
});

function SocialLinks() {
  return (
    <>
      <Link to={'https://www.instagram.com/borrowed.io/?igshid=YmMyMTA2M2Y%3D'}>
        <img src={insta} width={29.51} alt={'insta'} />
      </Link>
      <Link to={'https://www.linkedin.com/company/borrowed-io/'}>
        <img src={linkdin} width={29.51} alt={'linkdin'} />
      </Link>
      <Link to={'https://twitter.com/borrowedio'}>
        <img src={twitter} width={29.51} alt={'twitter'} />
      </Link>
    </>
  );
}

function AppFooter() {
  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.down('sm'));

  const footerDataFirst = [
    {
      id: 1,
      title: 'Support',
      link: '#'
    },
    {
      id: 2,
      name: 'Contact Us',
      link: '/contact-us'
    },
    {
      id: 3,
      name: 'Terms of Service',
      link: '#'
    },
    {
      id: 4,
      name: 'Privacy Policy',
      link: '/privacy-policy'
    }
  ];

  const footerDataSecond = [
    {
      id: 1,
      title: 'Help and Solutions',
      link: '#'
    },
    {
      id: 2,
      name: 'Talk to support',
      link: '#'
    },
    {
      id: 3,
      name: 'Support docs',
      link: '#'
    },
    {
      id: 4,
      name: 'System status',
      link: '#'
    },
    {
      id: 4,
      name: 'Covid response',
      link: '#'
    }
  ];

  const footerDataThird = [
    {
      id: 1,
      title: 'Product',
      link: '#'
    },
    {
      id: 2,
      name: 'About Us',
      link: '/about-us'
    },
    {
      id: 3,
      name: 'FAQ',
      link: '/faq'
    },
    {
      id: 4,
      name: 'Coming Soon',
      link: '/coming-soon'
    }
  ];

  return (
    <Box
      sx={{
        p: '40px 30px',
        marginTop: '6rem',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'center',
        gap: matches ? '4rem' : '10rem',
        alignItems: { xs: 'center', sm: 'start' },
        flexWrap: 'wrap'
      }}
    >
      <Box display="flex" gap={'6px'} alignItems={'center'}>
        <img src={logo} width={40} alt={'Logo'} />
        <Typography
          sx={{ fontFamily: 'Poppins', fontSize: '21px', color: 'black' }}
        >
          Borrowed
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          justifyContent: 'center',
          gap: '1rem'
        }}
      >
        {SocialLinks()}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: '3rem'
        }}
      >
        <FooterLinksv2 footerData={footerDataFirst} />
        <FooterLinksv2 footerData={footerDataSecond} />
        <FooterLinksv2 footerData={footerDataThird} />
      </Box>
      <Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'center',
            gap: '1rem'
          }}
        >
          {SocialLinks()}
        </Box>
        <Box sx={{ marginTop: '6rem' }}>
          <Typography>®Borrowed Technologies LLC. 2023</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(AppFooter);
