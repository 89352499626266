import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React from 'react';

const MeetSeller = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem'
        }}
      >
        <Box>
          <Box
            sx={
              matches
                ? { textAlign: 'center', marginBottom: '2rem' }
                : { marginBottom: '2rem' }
            }
          >
            <Typography variant="h6">Meet the seller</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '12vw', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Avatar
                alt="Remy Sharp"
                sx={{ width: 86, height: 86 }}
                src={require('../assets/images/client1.png')}
              />
              <Box>
                <Typography variant="subtitle1">Sport Girl</Typography>
                <Rating
                  name="read-only"
                  value={3}
                  sx={{ color: '#808000' }}
                  readOnly
                />
                <Typography variant="body1" sx={{ color: '#A0A0A0' }}>
                  20 complete sales
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: 'solid #808000',
                  fontSize: '20px',
                  textTransform: 'capitalize',
                  fontWeight: '400',
                  '&:hover': { backgroundColor: '#808000', color: 'white' }
                }}
              >
                Message
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MeetSeller;
