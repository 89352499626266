import React from 'react';
import Typography from './Typography';

const CustomLink = (props) => {
  const { name, index, title } = props;

  return (
    <>
      <Typography
        sx={{
          color: 'black',
          fontSize: '17px',
          mb: '6px',
          fontFamily: 'Inter'
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: 'black',
          fontSize: '16px',
          color: '#A6A6A6',
          fontFamily: 'Inter',
          lineHeight: '24.2px'
        }}
        variant={index < 2 ? 'h6' : 'body2'}
      >
        {name}
      </Typography>
    </>
  );
};

export default CustomLink;
