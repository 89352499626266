import { useMediaQuery, useTheme, Button, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../assets/images/borrowLogo.png';
import BorrowedTextLogo from '../assets/images/BorrowedTextLogo.png';
import Auth from '../auth/Auth';
import AppBar from './AppBar';
import DrawerComp from './Drawer';
import Toolbar, { styles as toolbarStyles } from './Toolbar';

const styles = (theme) => ({
  title: {
    fontSize: 24
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'flex-end'
  },
  left: {
    flex: 1
  },
  leftLinkActive: {
    color: theme.palette.common.black
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rightLink: {
    fontSize: 16,
    color: '#0D0D0D',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: '500',
    '&:hover': {
      fontWeight: '700',
      color: '#0D0D0D',
      borderBottom: '5px solid #86BA5E'
    }
  },
  linkSecondary: {
    color: theme.palette.secondary.main
  }
});

const NavbarView = ({ classes }) => {
  const auth = new Auth();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutAccount = () => {
    auth.logout();
  };

  return (
    <>
      <AppBar sx={{ marginTop: '2rem' }}>
        <Toolbar>
          <Box display="flex" paddingLeft={'2rem'}>
            <NavLink to={'/'}>
              <img src={logo} width={40} alt={'Logo'} />
              <img
                src={BorrowedTextLogo}
                width={90}
                style={{ marginLeft: '6px' }}
                alt={'text'}
              />
            </NavLink>
          </Box>

          {isMatch && <DrawerComp />}

          {isMatch ? null : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '3rem',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexGrow: 1,
                  paddingRight: '4rem'
                }}
              >
                <NavLink className={classes.rightLink} to={'/'}>
                  {'Home'}
                </NavLink>
                <NavLink className={classes.rightLink} to={'/faq'}>
                  {'FAQ'}
                </NavLink>
                <NavLink className={classes.rightLink} to={'/blog'}>
                  {'Blog'}
                </NavLink>
                <NavLink className={classes.rightLink} to={'/about-us'}>
                  {'About Us'}
                </NavLink>
                {auth.isAuthenticated() ? (
                  <>
                    <Button
                      sx={{
                        background: '#86BB5E',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                        color: 'white',
                        border: 'none',
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        '&:hover': {
                          background: '#86BB5E'
                        }
                      }}
                      onClick={handleClick}
                    >
                      Profile
                    </Button>
                    {/* ----Menu----- */}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      <Link
                        to={'/profile'}
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <MenuItem>My Account</MenuItem>
                      </Link>
                      <MenuItem
                        onClick={() => {
                          logOutAccount();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <NavLink style={{ textDecoration: 'none' }} to={'/login'}>
                    <Button
                      sx={{
                        background: '#86BB5E',
                        borderRadius: '5px',
                        padding: '5px 10px',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                        color: 'white',
                        border: 'none',
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        '&:hover': {
                          background: '#86BB5E'
                        }
                      }}
                    >
                      Login
                    </Button>
                  </NavLink>
                )}
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </>
  );
};

export default withStyles(styles)(NavbarView);
